import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  postscope3Category5WasteProduceData,
  scope3Category5UploadEvidence,
  updatescope3Category5Data,
} from "../../../features/scope3/category5/Scope3Category5Thunk";
import { setscope3Category5WasteProduceForm } from "../../../features/scope3/category5/Scope3Category5Slice";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";
import { processDate } from "./process";

const Category5WasteProducedFormEditAndCopyForm = ({
  closePurchasePopupForm,
  data,
  startDate,
  actionType,
}) => {
  const api = useApi();
  const dispatch = useDispatch();

  const siteData = useSelector((state) => state.scope3Category5.siteData);
  const currencyOptions = useSelector((state) => state.scope3Category5.currencyData);
  const unitData = useSelector((state) => state.scope3Category5.unitData);
  const wasteMaterial = useSelector((state) => state.scope3Category5.wasteMaterial);
  const wasteImpact = useSelector((state) => state.scope3Category5.wasteImpact);
  const formValue = useSelector((state) => state.scope3Category5.scope3Category5WasteProduceForm);
  const loading = useSelector((state) => state.common.isLoading);

  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({});

  const handleFormChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFile(files);
    } else {
      const updatedFormData = { ...formData, [name]: value };
      setFormData(updatedFormData);
      dispatch(setscope3Category5WasteProduceForm(updatedFormData));
    }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Please Upload evidence");
      return;
    }

    const form = document.querySelector("#purchase-Scope3Category5-form");

    if (form.checkValidity()) {
      dispatch(scope3Category5UploadEvidence({ files: file, api, type: 1 }))
        .then((res) => {
          if (res?.payload?.msg === "File uploaded.") {
            if (actionType === "edit") {
              dispatch(updatescope3Category5Data({ api, id: data.id }));
            } else if (actionType === "copy") {
              dispatch(postscope3Category5WasteProduceData({ api }));
            }
            closePurchasePopupForm();
          }
        });
    } else {
      form.querySelector('input[type="submit"]').click();
    }
  };

  useEffect(() => {
    if (data) {
      const updatedData = {
        produced_period_start: processDate(data.produced_period_start),
        produced_period_end: processDate(data.produced_period_end),
        produced_site_id: data.produced_site_id,
        produced_waste_material: data.produced_waste_material,
        produced_waste_material_id: data.produced_waste_material_id,
        produced_waste_impact: data.produced_waste_impact,
        produced_quantity: data.produced_quantity,
        produced_unit_id: data.produced_unit_id,
        produced_evidence: data.produced_evidence,
      };

      setFormData(updatedData);
      dispatch(setscope3Category5WasteProduceForm(updatedData));
    }
  }, [data, dispatch]);

  return (
    <>
      <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
        <form
          id="purchase-Scope3Category5-form"
          className="flex flex-col w-full py-4"
          style={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          onSubmit={handlePurchaseFormSubmit}
        >
          <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_period_start" className="text-xs py-1.5">
                Period Start<span className="text-red-500">*</span>
              </label>
              <input
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="produced_period_start"
                id="produced_period_start"
                value={formValue.produced_period_start || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_period_end" className="text-xs py-1.5">
                Period End<span className="text-red-500">*</span>
              </label>
              <input
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="produced_period_end"
                id="produced_period_end"
                value={formValue.produced_period_end || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                min={startDate}
                required
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_site_id" className="text-xs py-1.5">
                Site<span className="text-red-500">*</span>
              </label>
              <select
                name="produced_site_id"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.produced_site_id || ""}
              >
                <option value="" disabled>
                  Choose the site
                </option>
                {siteData &&
                  siteData.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.site}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_waste_material" className="text-xs py-1.5">
                Waste Material<span className="text-red-500">*</span>
              </label>
              <select
                name="produced_waste_material"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.produced_waste_material || ""}
              >
                <option value="" disabled>
                  Choose the waste material
                </option>
                {wasteMaterial &&
                  wasteMaterial.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.waste_material}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_waste_impact" className="text-xs py-1.5">
                Waste Impact <span className="text-red-500">*</span>
              </label>
              <select
                name="produced_waste_impact"
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                required
                value={formValue.produced_waste_impact || ""}
              >
                <option value="" disabled>
                  Choose the Waste Impact
                </option>
                {wasteImpact &&
                  wasteImpact.map((i) => (
                    <option key={i.id} value={i.waste_impact}>
                      {i.waste_impact}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_quantity" className="text-xs py-1.5">
                Treated Quantity
              </label>
              <input
                type="number"
                name="produced_quantity"
                value={formValue.produced_quantity || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
                placeholder="Type the quantity"
                min="0"
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_unit_id" className="text-xs py-1.5">
                Unit
              </label>
              <select
                name="produced_unit_id"
                value={formValue.produced_unit_id || ""}
                onChange={handleFormChange}
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
              >
                <option value="" disabled>
                  Choose the unit
                </option>
                {unitData &&
                  unitData.map((unit, index) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unit}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-span-1 flex flex-col">
              <label htmlFor="produced_evidence" className="text-xs py-1.5">
                Evidence <span className="text-red-500">*</span>
              </label>
              <input
                disabled={loading}
                type="file"
                name="produced_evidence"
                multiple
                accept=".jpg, .jpeg, .png, .pdf, .zip"
                onChange={handleFormChange}
                required
                className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
              />
            </div>
          </div>

          <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
            <div className="w-1/6 flex flex-col">
              <button
                disabled={loading}
                type="button"
                className="py-2 px-6 text-green-600 text-sm rounded-md"
                onClick={() => closePurchasePopupForm()}
              >
                Cancel
              </button>
            </div>
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* </div> */}
    </>
  );
};

export default Category5WasteProducedFormEditAndCopyForm;
