import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, registerUser } from "../../features/auth/authThunks";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  
  // Initial state with provided fields
  const initialState = {
    username: "",
    password: "",
    user_name: "",
    email: "",
    first_name: "",
    last_name: "",
    confirm_password: "",
    company_name: "",
    plan_type_id: 1
  };

  // State for form inputs
  const [credentials, setCredentials] = useState(initialState);
const [USERNAME,SETUSERNAME]=useState('')
const [PASSWORD,SETPASSWORD]=useState('')
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(credentials.password)) {
      toast.error(
        "Password must contain at least 1 uppercase letter, 1 special character, 1 number, and be at least 8 characters long"
      );
      return;
    }
    if (!isValidEmail(credentials.email)) {
      toast.error("Please enter a valid email address");
      return
    }
    if (credentials.password !== credentials.confirm_password) {
      toast.error("New password and confirm password do not match");
      return;
    }
    try {
      let res=await dispatch(registerUser(credentials,USERNAME,PASSWORD));
      // console.log(res)
      if(res?.payload?.success){

        Navigate("/register-success-page");
      }
      // Dispatch the login thunk with the credentials
      // After successful login, you should have tokens in the Redux store
    } catch (error) {
      // Handle login failure
      console.error("Register failed:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen lg:w-1/2">
      <h1 className="text-3xl font-semibold mb-8 text-left w-full px-10">
        Welcome to&npsp;<span>{window.location.hostname.split(".")[0]}</span>
      </h1>
      <div className="w-full lg:px-10">
        <form
          onSubmit={handleLogin}
          className="space-y-4 p-4 lg:p-0 lg:pt-4 lg:pb-8 transition-all duration-300 ease-in-out transform "
        >
          <div className="flex justify-between items-center gap-2">
            {/* First Name */}
            <div className="flex-1">
              <label htmlFor="first_name" className="block mb-1 text-gray-600">
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={credentials.first_name}
                onChange={handleInputChange}
                className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
                placeholder="Your first name"
                required
              />
            </div>
            {/* Last Name */}
            <div className="flex-1">
              <label htmlFor="last_name" className="block mb-1 text-gray-600">
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={credentials.last_name}
                onChange={handleInputChange}
                className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
                placeholder="Your last name"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block mb-1 text-gray-600">
              Email<span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={credentials.email}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Your email"
              required
            />
          </div>
          <div>
            <label htmlFor="user_name" className="block mb-1 text-gray-600">
              User Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              value={credentials.user_name}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Your user name"
              required
            />
          </div>

          <div>
            <label htmlFor="company_name" className="block mb-1 text-gray-600">
              Company Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="company_name"
              name="company_name"
              value={credentials.company_name}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Your company name"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block mb-1 text-gray-600">
              Password<span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Enter Password"
              required
            />
          </div>
          <div>
            <label
              htmlFor="confirm_password"
              className="block mb-1 text-gray-600"
            >
              Confirm Password<span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              value={credentials.confirm_password}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Confirm Password"
              required
            />
          </div>
          <div className="hidden">
            <label htmlFor="plan_type_id" className="block mb-1 text-gray-600">
              Plan Type ID<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              id="plan_type_id"
              name="plan_type_id"
              value={1}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Your plan type ID"
              required
            />
          </div>
          <div className="flex flex-col lg:flex-row justify-start items-center space-y-2 lg:space-y-0 lg:space-x-2">
            <button
              type="submit"
              className="w-full lg:w-auto px-10 py-3 bg-[#02AB6C] text-white rounded hover:bg-[#02AB6C] font-bold transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;
