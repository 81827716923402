import React from "react";
import { ReactComponent as ReportIcon } from "../../app/assets/ReportingIcon.svg";
import Header from "../../components/common/Header";
function GRI() {
  return (
    <div>
      <Header pageTitle={"GRI"} PageIcon={ReportIcon} />
      <div className="container flex justify-center items-center">Under construction</div>
    </div>
  );
}

export default GRI;
