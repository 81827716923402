export const hasAccess = (role, type, module, submodule, permissions) => {
  const typePermissions = permissions || {}; // Use provided permissions or fallback to an empty object
  const getPermissions = (permissionType) => {
    const {
      modules = [],
      submodules = [],
      is_enabled = false,
    } = typePermissions[permissionType] || {};
    return { modules, submodules, is_enabled };
  };

  // Function to check if access to module and submodule is granted
  function hasModuleAndSubmoduleAccess({ modules, submodules, is_enabled }) {
    const hasModuleAccess =
      ((modules.includes(-1) || modules.includes(module)) &&
        (typePermissions?.modules?.includes(module) ||
          typePermissions?.modules?.includes(-1))) ||
      module === -1;
    const hasSubmoduleAccess =
      ((submodules.includes(-1) || submodules.includes(submodule)) &&
        (typePermissions?.submodules?.includes(submodule) ||
          typePermissions?.submodules?.includes(-1))) ||
      submodule === -1;
    return is_enabled && hasModuleAccess && hasSubmoduleAccess;
  }
  if (role === "admin") {
    // console.log(hasModuleAndSubmoduleAccess(getPermissions("admin")),"Admin")
    return (
      hasModuleAndSubmoduleAccess(getPermissions("admin"))
    );
  }

  // -2 for general info
  const { is_enabled } = getPermissions(type);

  switch (type) {
    case "admin":
      return is_enabled;

      case "data_entry":
    case "management":
    case "auditing":
    case "reporting":
    case "visualization":
      return hasModuleAndSubmoduleAccess(getPermissions(type));

    default:
      return false;
  }
};
