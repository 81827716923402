
// import React, { useEffect } from "react";
// import { Navigate, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { validateMandotrySteps } from "../../features/auth/authThunks";
// import api from "../../services/api";
// import {
//   setleftSideBarScreenShow,
//   setmandatoryStep,
//   setmandatoryStepsCompleted,
// } from "../../features/auth/authSlice";

// const PublicRoute = ({ element, restricted, ...rest }) => {
//   const navigate = useNavigate();
//   const { isAuthenticated,accessToken, mandatoryStepsCompleted } = useSelector(
//     (state) => state.auth
//   );
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const checkMandatorySteps = async () => {
//       if (isAuthenticated) {
//         const res = await dispatch(validateMandotrySteps({ api }));
       
//         const stepsCompleted = res.payload?.data?.mandatory_steps_completed;
//         const stepUrl = res.payload?.data?.Step?.step_url;
//         dispatch(setmandatoryStep(res.payload?.data?.Step?.is_mandatory));

//         if (!stepsCompleted) {
//           navigate(stepUrl);
//         } else {
//           dispatch(setmandatoryStepsCompleted(true));
//         }
//       } else {
//         dispatch(setmandatoryStepsCompleted(false));
//         navigate("/login")
//       }
//     };

//     checkMandatorySteps();
//   }, [isAuthenticated, dispatch, navigate]);



//   if (mandatoryStepsCompleted) {
//     dispatch(setleftSideBarScreenShow(true))
//     return <Navigate to="/energy/emissions" replace />;
//   }

//   // Render the provided element if mandatory steps are not required
//   return React.cloneElement(element, rest);
// };

// export default PublicRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRouteByModuleAndSubmodule } from "../../app/routePermissions";

const PublicRoute = ({ element, restricted, ...rest }) => {
  const {isAuthenticated,accessToken} = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  let mandatoryStepsCompleted = JSON.parse(localStorage.getItem('mandatoryStepsCompleted') || 'false');
  // console.log( mandatoryStepsCompleted)
if (isAuthenticated&&mandatoryStepsCompleted) {
  let route=getRouteByModuleAndSubmodule(accessToken)
    return <Navigate to={route} replace />;
  }
  // Render the provided element if not restricted
  return React.cloneElement(element, rest);
};

export default PublicRoute;
