import React from "react";
import ReactECharts from "echarts-for-react";

const PieChart = ({ option }) => {
  // console.log(option)
  const dataItemCount = option.series[0]?.data?.length || 0;
  
  // Calculate height based on the number of items: 100px for base height + 30px per data item
  const chartHeight = Math.min(400 + dataItemCount * 20, 1000); // Set a max height of 1000px to prevent excessive growth
const color=[
  '#5470C6', '#91CC75', '#EE6666', '#FAC858', '#73C0DE', '#3BA272',
  '#FC8452', '#9A60B4', '#EA7CCC', '#D14A61', '#675BBA', '#F5994E',
  '#A0A7E6', '#7F9EC6', '#91C7AE', '#D48265', '#61A0A8', '#C4CCD3',
  '#D53A35', '#4E91D5', '#6B4A3E', '#CA8622', '#BDA29A', '#CC3C5D',
  '#00A29B', '#FF7F50', '#6495ED', '#FFD700', '#32CD32', '#FFA07A',
  '#20B2AA', '#87CEEB', '#778899', '#B0E0E6', '#FF6347', '#4682B4',
  '#8B0000', '#008080', '#FFD700', '#4B0082', '#DC143C', '#2E8B57',
  '#FF69B4', '#FF4500', '#808000', '#FF1493', '#00CED1', '#1E90FF',
  '#8A2BE2', '#A52A2A', '#5F9EA0', '#FF8C00', '#B22222', '#DAA520',
  '#228B22', '#FFDAB9', '#FF00FF', '#7FFF00', '#FA8072', '#F08080',
  '#FFB6C1', '#E0FFFF', '#FFE4E1', '#7B68EE', '#D2691E', '#9ACD32',
  '#B8860B', '#9932CC', '#00FF7F', '#40E0D0', '#FFFAF0', '#8FBC8F',
  '#BDB76B', '#FFDEAD', '#7CFC00', '#F0E68C', '#E6E6FA', '#FFF0F5',
  '#00FF00', '#F4A460', '#D8BFD8', '#FF7F50', '#FF6347', '#FFDAB9',
  '#FFE4B5', '#FFFACD', '#00FA9A', '#DDA0DD', '#FFF5EE', '#B0C4DE',
  '#B0E0E6', '#87CEEB', '#00BFFF', '#FFD700', '#32CD32', '#FFA500',
  '#CD5C5C', '#D3D3D3', '#4682B4', '#FF00FF', '#00CED1', '#808080'
]
  return (
    <div 
    style={{ width: "100%", height: "500px" }}
    >
      <ReactECharts
        option={{...option,color}}
        style={{ height: "100%", width: "100%" }}
        opts={{ renderer: "canvas" }}
        notMerge={true}
        lazyUpdate={true}
      />
    </div>
  );
};

export default PieChart;

// import React, { useEffect } from "react";
// import * as echarts from "echarts";

// const PieChart = ({ option }) => {
//   useEffect(() => {
//     const chartDom = document.getElementById("pie-chart");
//     const myChart = echarts.init(chartDom);

//     myChart.setOption(option);

//     // Clean up function to dispose the chart when the component unmounts
//     return () => {
//       myChart.dispose();
//     };
//   }, [option]);

//   return <div id="pie-chart" style={{ width: '100%', height: '400px' }} />;
// };

// export default PieChart;
