import React, { useEffect, useState } from "react";

import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";
import { ReactComponent as FuelIcon } from "../../app/assets/FuelIcon.svg";
import Header from "../../components/common/Header";
import AnalyzeHeader from "../../components/Analyze/AnalyzeHeader";

import { 

  useDispatch,
   useSelector } from "react-redux";
import { calculateEnergyUsageChange, calculateRenewableEnergyUsageChange, currentYearLastYearEmissionDetail,  } from "../VisualizationFeature/Redux/processData/ProcessDataforWaste";
import Filter from "../VisualizationFeature/components/Filter";
import { fetchChartDataThunk } from "../VisualizationFeature/Redux/chartthunk";
import useApi from "../../customHooks/useApi";
import Category5Main from "./scope3/Category5Main";
import { fetchCat5DataThunk } from "../VisualizationFeature/Redux/category5/analyzeThunk";
function AnalyzeScope3Category5() {
  const dispatch = useDispatch();
  const api=useApi()
  const [StackBreakDown] = useState(["waste_material", "treatment_mechanism"]);
  const {wasteTreatmentOptions,siteOptions,wasteMaterialOptions,data,dataForCurrentYearChange,financialYear}=useSelector(state=>state.analyzeCategory5)
  const [currentFinancialYear, setCurrentFinancialYear] = useState(financialYear.length ? financialYear[0] : '');
  const [emissionChange,setEmissonChange]=useState({})
  const [renewableEnergyChange,setrenewableEnergy]=useState({})
  const [EnergyChange,setEnergyChange]=useState({})
  const [chartDataArray] = useState([
    { title: "Emissions by waste material", dataKey: "waste_material" },
    { title: "Emissions by Facility", dataKey: "site" },
    { title: "Emission by Treatment mechanism", dataKey: "treatment_mechanism" },
  ]); 
  const [fuelBreakDownOptions] = useState([
    { value: "site", label: "Facility" },
    { value: "waste_material", label: "Waste Material" },
    { value: "treatment_mechanism", label: "Treatment Mechanism" },
  ]);
  const [filterBy, setFilterBy] = useState({
    site: '',
    waste_material: '',
    treatment_mechanism: ''
  });
  const handleFilterChange = (name, value) => {
    if(name==="financialYear"){
      setCurrentFinancialYear(value)
    }else{
      setFilterBy(prevFilterBy => ({
        ...prevFilterBy,
        [name]: value
      }));

    }
  };
  
  const emissionData = [
    {
      title: "Emissions",
      info: (emissionChange?.currentYear||0)+" Co2e",
      time: "",
      status: (emissionChange?.percentageChange||0) >0 ?"positive":"negative",
      per:  `${emissionChange?.percentageChange !== Infinity ? `${Math.abs(emissionChange?.percentageChange)}% YOY${emissionChange?.percentageChange > 0 ? "↑" : "↓"}` : ""}`, year:currentFinancialYear,
      theme: "red",
    },
    {
      title: "Energy Usage",
      info: EnergyChange?.currentYear+" kWh",
      time: "",
      status: (EnergyChange?.percentageChange||0) >0 ?"positive":"negative",
      per: `${EnergyChange?.percentageChange !== Infinity ? `${Math.abs(EnergyChange?.percentageChange)}% YOY${EnergyChange?.percentageChange > 0 ? "↑" : "↓"}` : ""}`,
      year:currentFinancialYear,
      theme: "yellow",
    },
    // {
    //   title: "Emissions intensity",
    //   info: "32",
    //   time: "",
    //   status: "positive",
    //   per: "25",
    //   theme: "green",
    // },
  ];
//  console.log(data)
  const energyData = [
 
];

useEffect(()=>{
  setEmissonChange(currentYearLastYearEmissionDetail(dataForCurrentYearChange,Number(currentFinancialYear)))
  // setrenewableEnergy(calculateRenewableEnergyUsageChange(dataForCurrentYearChange,Number(currentFinancialYear)))
  // setEnergyChange(calculateEnergyUsageChange(dataForCurrentYearChange,Number(currentFinancialYear)))
},[data,dataForCurrentYearChange,currentFinancialYear])
useEffect(() => {
  dispatch(fetchCat5DataThunk({api}));
}, [dispatch]);
useEffect(() => {
  if (financialYear.length > 0) {
    setCurrentFinancialYear(financialYear[0]);
  }
}, [financialYear]);
  return (
    <>
     <Header PageIcon={FuelIcon} pageTitle={"Scope 1"} />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <AnalyzeHeader emissionData={emissionData} energyData={energyData} />

        {/* filter */}

        <div className="flex flex-col  rounded-md mt-[18px]  w-full border p-2">
          <div className="flex justify-between py-2 items-center  bg-gray-100 rounded">
            <span className="text-xl px-2"></span>
            
            <div className="flex justify-between items-center gap-2 px-2">
            <FilterMenuIcon className="cursor-pointer" />
            <Filter
                label="Financial Year"
                options={financialYear}
                selectedValue={currentFinancialYear}
                onChange={(value) => handleFilterChange('financialYear', value)}
              />
            <Filter

                label="Facilty"

                options={siteOptions}
                selectedValue={filterBy.site}
                onChange={(value) => handleFilterChange('site', value)}
              />
             
              <Filter
                label="Waste material"
                options={wasteMaterialOptions}
                selectedValue={filterBy.waste_material}
                onChange={(value) => handleFilterChange('waste_material', value)}
              />
             
              <Filter
                label="Treatment mechanism"
                options={wasteTreatmentOptions}
                selectedValue={filterBy.treatment_mechanism}
                onChange={(value) => handleFilterChange('treatment_mechanism', value)}
              /> 
            </div>
          </div>
          <div>
            <Category5Main height={"500px"} width={"100%"} filterBy={filterBy} data={data} chartDataArray={chartDataArray} Options={fuelBreakDownOptions} StackBreakDown={StackBreakDown} currentFinancialYear={currentFinancialYear}/>
          </div>
         
         
         
        </div>
      </div>
    
    </>
  );
}

export default AnalyzeScope3Category5;
