export function calculateDaysBetween(date1, date2) {
  const firstDate = typeof date1 === "string" ? new Date(date1) : date1;
  const secondDate = typeof date2 === "string" ? new Date(date2) : date2;

  const timeDifference = Math.abs(firstDate - secondDate);

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}
export function calculateTreatedQuantity(days, totalDays) {
  if (!totalDays) {
    return 0;
  }
  if (!days) {
    return 0;
  }
  return (days / totalDays) * 100;
}
function formatDate(dateStr) {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  }
  
  function isValidDate(dateStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateStr);
  }
  
  export function processDate(dateStr) {
    if (isValidDate(dateStr)) {
      return dateStr;
    } else {
      return formatDate(dateStr);
    }
  }
  

  