import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  validateMandotrySteps,
} from "../../features/auth/authThunks";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import { getRouteByModuleAndSubmodule } from "../../app/routePermissions";
import PasswordInput from "./PasswordInputField";


const LoginForm = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  // State for username and password inputs
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    // if (!passwordRegex.test(credentials.password)) {
    //   toast.error(
    //     "Password must contain at least 1 uppercase letter, 1 special character, 1 number, and be at least 8 characters long"
    //   );
    //   return;
    // }

    try {
      const loginResult = await dispatch(loginUser(credentials));
      if (loginResult?.payload?.success) {
        const res = await dispatch(validateMandotrySteps({ api, accessToken }));
        const stepsCompleted = res.payload?.data?.mandatory_steps_completed;
        const stepUrl = res.payload?.data?.Step?.step_url;

        // console.log(stepsCompleted);
        if (stepsCompleted) {
          localStorage.setItem("mandatoryStepsCompleted", JSON.stringify(true));
          const route=getRouteByModuleAndSubmodule(accessToken||loginResult?.payload?.data?.data?.accessToken)
          Navigate(route);
        } else {
          Navigate(stepUrl);
        }
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen lg:w-1/2">
      <h1 className="text-3xl font-semibold mb-8 text-left w-full px-10">
        Welcome to&nbsp;
        <span className="capitalize">
          {window.location.hostname.split(".")[0]}
        </span>
      </h1>
      <div className="w-full lg:px-10">
        <form
          onSubmit={handleLogin}
          className="space-y-4 p-4 lg:p-0 lg:pt-4 lg:pb-8 transition-all duration-300 ease-in-out transform "
        >
          <div>
            <label htmlFor="username" className="block mb-1 text-gray-600">
              Username<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={credentials.username}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Your username"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block mb-1 text-gray-600">
              Password<span className="text-red-500">*</span>
            </label>

            <PasswordInput
              type="password"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Enter Password"
              required={true}
            />
          </div>
          <div className="">
            <span className="text-[#02AB6C]">
              Track, Measure and Report Today!
            </span>
          </div>
          <div className="flex flex-col lg:flex-row justify-start items-center space-y-2 lg:space-y-0 lg:space-x-2">
            <button
              type="submit"
              className="w-full lg:w-auto px-10 py-3 bg-[#02AB6C] text-white rounded hover:bg-[#02AB6C] font-bold transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              Login
            </button>
            <div className="flex justify-start items-center space-x-1">
              <span className="text-gray-600 text-sm">
                Forgot Your password?
              </span>
              <Link
                to={"/forgotPassword"}
                className="text-[#02AB6C] hover:underline"
              >
                Click Here
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
