import React from "react";
import { ReactComponent as IIcon } from "../../../app/assets/iCircleIcon.svg";
function EmissionCard({ title, info, time, status, per, theme,year }) {
  let style = {
    border: `1px solid ${theme}`,
    borderLeft: `10px solid ${theme}`,
  };
  return (
    <div className="min-w-72 max-w-fit h-[145px] shadow p-4 border rounded-lg flex flex-shrink-0 flex-col justify-between">
      <div className="flex gap-2 justify-between">
        <div className="flex gap-2 item-start">
          <h3 className="font-semibold "> {title}</h3>
          <IIcon className="w-4 h-4 flex flex-shrink-0  mt-1" />
        </div>
        {year&&<div className="bg-[#a8d7c6] text-[#1BA370] w-fit px-3 flex-shrink-0 rounded-full text-sm text-center flex justify-center items-center shadow-lg font-medium">{year||""}</div>}
      </div>
      <span className="font-thin text-xs text-gray-400">{time || ""}</span>
      <div
        style={style}
        className={`flex  flex-shrink-0 w-full justify-between items-center p-2 px-2 mt-4 rounded-xl bg-${theme}-200 gap-4`}
      >
        <p className="flex flex-shrink-0 ">{info}</p>
        {
          <p
            className={`text-center w-fit text-[10px] flex-shrink-0 flex justify-center items-center ${
              status !== "positive" ? "text-green-700" : "text-red-700"
            }`}
          >
            {per}
          </p>
        }
      </div>
    </div>
  );
}

export default EmissionCard;
