// src/components/common/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasAccess } from "../../app/permissionService";
import { routePermissions } from "../../app/routePermissions";
import { jwtDecode } from "jwt-decode";
import MainLayout from "./MainLayout";
const ProtectedRoute = ({ element: Component, path, ...rest }) => {
  const { user } = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.accessToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login" replace />;
  }
  const decodedToken = jwtDecode(token);
  const permissions = decodedToken.member.access;
  const role = decodedToken?.member?.role_type.toLowerCase();
  // Determine required module and submodule for the route
  const routePermission = routePermissions[path] || {};
  const { module, submodule, type } = routePermission;

  const hasRequiredAccess = () => {
    if (role === "admin") {
      // for admin
      return true;
    }
    if (module === null && submodule === null && type === null) return false; // Always not accessible route
    return hasAccess(role, type, module, submodule, permissions);
  };

  return (
    <MainLayout>
      {hasRequiredAccess() ? (
        <Component {...rest} />
      ) : (
        <Navigate to="/access-denied" />
      )}
    </MainLayout>
  );
};

export default ProtectedRoute;
