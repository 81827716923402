// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import {
//   UploadLogo,
//   postCompanyDetails,
//   validateMandotrySteps,
// } from "../../features/auth/authThunks";
// import useApi from "../../customHooks/useApi";
// import { setfirstLogin, setmandatoryStep } from "../../features/auth/authSlice";
// const CompanyDetailsInputForm = () => {
//   const api = useApi();
//   const { mandatoryStepData, isAuthenticated } = useSelector(
//     (state) => state.auth
//   );
//   const countryoptions = useSelector((state) => state.auth.country);
//   const [yearOfIncorporationoptions, setyearOfIncorporationoptions] = useState(
//     []
//   );
//   const [formData, setFormData] = useState({
//     industry: "",
//     country_id: "",
//     fiscal_year: "",

//     logo_path: null,
//   });

//   const Navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleInputChange = async (e) => {
//     const { name, value } = e.target;
//     if (name === "logo_path") {
//       const file = e.target.files[0];

//       const res = await dispatch(UploadLogo({ file, api }));

//       setFormData((prevState) => ({
//         ...prevState,
//         logo_path: res?.payload?.path,
//       }));
//     } else {
//       setFormData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     }
//   };

//   const handleContinue = (e) => {
//     e.preventDefault();
//     try {
//       dispatch(
//         postCompanyDetails({
//           formData,
//           api,
//           step_id: mandatoryStepData?.Step?.id,
//         })
//       ).then((res) => {
//         if (res?.payload?.success) {
//           Navigate("/energy/emissions");
//           localStorage.setItem("firstLogin", false);
//           dispatch(setfirstLogin(false));
//         }
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     const options = [];
//     for (let year = 1800; year <= new Date().getFullYear(); year++) {
//       options.push({ id: year, yearOfIncorporation: String(year) });
//     }
//     setyearOfIncorporationoptions(options.reverse());
//   }, []);
//   return (
//     <div className="flex flex-col items-center justify-center h-screen lg:w-1/2">
//       <h1 className="text-2xl font-semibold text-left w-full px-10">
//         Company Details
//       </h1>
//       <div className="w-full lg:px-10">
//         <form
//           onSubmit={handleContinue}
//           className="space-y-1 p-1 lg:p-0 lg:pt-4 lg:pb-8 transition-all duration-300 ease-in-out transform "
//         >
          
//           <div>
//             <label
//               htmlFor="registration_number"
//               className="block mb-1 text-gray-600"
//             >
//               Company Registration Number<span className="text-red-500">*</span>
//             </label>
//             <input
//               type="text"
//               id="registration_number"
//               name="registration_number"
//               value={formData.registration_number}
//               onChange={handleInputChange}
//               className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
//               placeholder="Company Registration Number"
//               required
//             />
//           </div>
//           <div>
//             <label
//               htmlFor="year_of_incorporation"
//               className="block mb-1 text-gray-600"
//             >
//               Year of Incorporation<span className="text-red-500">*</span>
//             </label>
//             <select
//               id="year_of_incorporation"
//               name="year_of_incorporation"
//               value={formData.year_of_incorporation}
//               onChange={handleInputChange}
//               className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
//               required
//             >
//               <option value="">Select year</option>
//               {yearOfIncorporationoptions.map((item) => {
//                 return (
//                   <option
//                     className="w-fit"
//                     value={item.yearOfIncorporation}
//                     key={`${item.id}`}
//                   >
//                     {item.yearOfIncorporation}
//                   </option>
//                 );
//               })}
//             </select>
//           </div>
//           <div>
//             <label htmlFor="industry" className="block mb-1 text-gray-600">
//               NIC/ISIC Code<span className="text-red-500">*</span>
//             </label>
//             <input
//               type="text"
//               maxLength={4}
//               id="industry"
//               name="industry"
//               value={formData.industry}
//               onChange={handleInputChange}
//               className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
//               placeholder="NIC/ISIC Code"
//               required
//             />
//           </div>
//           <div>
//             <label htmlFor="country_id" className="block mb-1 text-gray-600">
//               Country of Registration<span className="text-red-500">*</span>
//             </label>
//             <select
//               id="country_id"
//               name="country_id"
//               value={formData.country_id}
//               onChange={handleInputChange}
//               className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
//               required
//             >
//               <option value="">Select Country</option>
//               {countryoptions.map((item) => {
//                 return (
//                   <option
//                     value={item.id}
//                     className="w-fit"
//                     key={`${item.sortName}`}
//                   >
//                     {item.country}
//                   </option>
//                 );
//               })}
//             </select>
//           </div>
//           <div>
//             <label htmlFor="fiscal_year" className="block mb-1 text-gray-600">
//               Fiscal Year<span className="text-red-500">*</span>
//             </label>
//             <select
//               id="fiscal_year"
//               name="fiscal_year"
//               value={formData.fiscal_year}
//               onChange={handleInputChange}
//               className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
//               required
//             >
//               <option value="">Select Fiscal Year</option>
//               <option value="JAN-DEC">JAN-DEC</option>
//               <option value="APR-MAR">APR-MAR</option>
//             </select>
//           </div>

//           <div>
//             <label htmlFor="logo_path" className="block mb-1 text-gray-600">
//               Logo<span className="text-red-500">*</span>
//             </label>
//             <input
//               type="file"
//               id="logo_path"
//               name="logo_path"
//               onChange={handleInputChange}
//               className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
//               required
//             />
//           </div>

//           <div className="mt-4 flex justify-start">
//             <button
//               type="submit"
//               className="w-full lg:w-auto px-10 py-3 bg-[#02AB6C] text-white rounded hover:bg-[#02AB6C] font-bold transition-all duration-300 ease-in-out transform hover:scale-105 mt-5"
//             >
//               Continue
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CompanyDetailsInputForm;
import { ReactComponent as AddIcon } from "../../app/assets/AddIcon.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  UploadLogo,
  postCompanyDetails,
  validateMandotrySteps,
} from "../../features/auth/authThunks";
import useApi from "../../customHooks/useApi";
import { setfirstLogin, setmandatoryStep } from "../../features/auth/authSlice";
import { toast } from "react-toastify";

const CompanyDetailsInputForm = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const api = useApi();
  const { mandatoryStepData, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const countryoptions = useSelector((state) => state.auth.country);
  const [yearOfIncorporationoptions, setyearOfIncorporationoptions] = useState(
    []
  );
  const [formData, setFormData] = useState({
    industry: "",
    country_id: "",
    fiscal_year: "",
    logo_path: null,
  });
  const [industryCodes, setIndustryCodes] = useState([]);
  const [errors, setErrors] = useState({});

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "logo_path") {
      const file = e.target.files[0];
      const res = await dispatch(UploadLogo({ file, api }));
      setFormData((prevState) => ({
        ...prevState,
        logo_path: res?.payload?.path,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleIndustryChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      industry: e.target.value,
    }));
  };

  const addIndustryCode = () => {
    const codes = formData.industry.split(",").map((code) => code.trim());
    const isValid = codes.every((code) => code.length === 4);
    if (isValid) {
      setIndustryCodes((prevCodes) => [...prevCodes, ...codes]);
      setFormData((prevState) => ({
        ...prevState,
        industry: "",
      }));
      setErrors((prevState) => ({ ...prevState, industry: "" }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        industry: "Each code must be exactly 4 characters long.",
      }));
    }
  };

  const removeIndustryCode = (codeToRemove) => {
    setIndustryCodes((prevCodes) =>
      prevCodes.filter((code) => code !== codeToRemove)
    );
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (industryCodes.length === 0) {
      toast.error("Please add industry code", {
        theme: "black",
      });
      return;
    }
  
    try {
      const postResult = await dispatch(
        postCompanyDetails({
          formData: { ...formData, industry: industryCodes.join(",") },
          api,
          step_id: mandatoryStepData?.Step?.id,
        })
      );
  
      if (postResult?.payload?.success) {
        const validateResult = await dispatch(validateMandotrySteps({ api, accessToken }));
        const stepsCompleted = validateResult.payload?.data?.mandatory_steps_completed;
        const stepUrl = validateResult.payload?.data?.Step?.step_url;
  
        // console.log(stepsCompleted);
        if (stepsCompleted) {
          localStorage.setItem("mandatoryStepsCompleted", JSON.stringify(true));
          Navigate("/energy/emissions");
        } else {
          Navigate(stepUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    const options = [];
    for (let year = 1800; year <= new Date().getFullYear(); year++) {
      options.push({ id: year, yearOfIncorporation: String(year) });
    }
    setyearOfIncorporationoptions(options.reverse());
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen lg:w-1/2">
      <h1 className="text-2xl font-semibold text-left w-full px-10">
        Company Details
      </h1>
      <div className="w-full lg:px-10">
        <form
          onSubmit={handleContinue}
          className="space-y-1 p-1 lg:p-0 lg:pt-4 lg:pb-8 transition-all duration-300 ease-in-out transform "
        >
          <div>
            <label
              htmlFor="registration_number"
              className="block mb-1 text-gray-600"
            >
              Company Registration Number<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="registration_number"
              name="registration_number"
              value={formData.registration_number}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              placeholder="Company Registration Number"
              required
            />
          </div>
          <div>
            <label
              htmlFor="year_of_incorporation"
              className="block mb-1 text-gray-600"
            >
              Year of Incorporation<span className="text-red-500">*</span>
            </label>
            <select
              id="year_of_incorporation"
              name="year_of_incorporation"
              value={formData.year_of_incorporation}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              required
            >
              <option value="">Select year</option>
              {yearOfIncorporationoptions.map((item) => {
                return (
                  <option
                    className="w-fit"
                    value={item.yearOfIncorporation}
                    key={`${item.id}`}
                  >
                    {item.yearOfIncorporation}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label htmlFor="industry" className="block mb-1 text-gray-600">
              NIC/ISIC Code<span className="text-red-500">*</span>
            </label>
            <div className="flex items-center">
              <input
                type="text"
                maxLength={4}
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleIndustryChange}
                className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
                placeholder="NIC/ISIC Code"
                required={!industryCodes.length>0}
              />
            
                <AddIcon className="ml-2 cursor-pointer h-10 w-10" onClick={addIndustryCode}/>
              
            </div>
            {errors.industry && (
              <p className="text-red-500 text-sm mt-1">{errors.industry}</p>
            )}
          </div>
          <div className="flex flex-wrap mt-2">
            {industryCodes.map((code, index) => (
              <span
                key={index}
                className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full mr-2 mb-2 flex items-center"
              >
                {code}
                <button
                  type="button"
                  onClick={() => removeIndustryCode(code)}
                  className="ml-2 text-red-500"
                >
                  &times;
                </button>
              </span>
            ))}
          </div>
          <div>
            <label htmlFor="country_id" className="block mb-1 text-gray-600">
              Country of Registration<span className="text-red-500">*</span>
            </label>
            <select
              id="country_id"
              name="country_id"
              value={formData.country_id}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              required
            >
              <option value="">Select Country</option>
              {countryoptions.map((item) => {
                return (
                  <option
                    value={item.id}
                    className="w-fit"
                    key={`${item.sortName}`}
                  >
                    {item.country}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label htmlFor="fiscal_year" className="block mb-1 text-gray-600">
              Fiscal Year<span className="text-red-500">*</span>
            </label>
            <select
              id="fiscal_year"
              name="fiscal_year"
              value={formData.fiscal_year}
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              required
            >
              <option value="">Select Fiscal Year</option>
              <option value="JAN-DEC">JAN-DEC</option>
              <option value="APR-MAR">APR-MAR</option>
            </select>
          </div>
          <div>
            <label htmlFor="logo_path" className="block mb-1 text-gray-600">
              Logo<span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              id="logo_path"
              name="logo_path"
              onChange={handleInputChange}
              className="w-full p-2 border outline-none border-gray-300 rounded focus:border-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mt-4 flex justify-start">
            <button
              type="submit"
              className="w-full lg:w-auto px-10 py-3 bg-[#02AB6C] text-white rounded hover:bg-[#02AB6C] font-bold transition-all duration-300 ease-in-out transform hover:scale-105 mt-5"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyDetailsInputForm;
