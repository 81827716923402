import { setmandatoryStep, setmandatoryStepsCompleted } from '../features/auth/authSlice';
import { validateMandotrySteps } from '../features/auth/authThunks';
import { setIsLoading } from '../features/common/commonSlice';
import api from '../services/api';

export const validationMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  const { type, meta } = action;
  const state = getState();
  const isAuthenticated = state.auth.isAuthenticated;
  let mandatoryStepsCompleted = JSON.parse(localStorage.getItem('mandatoryStepsCompleted') || 'false');
  if (isAuthenticated && !mandatoryStepsCompleted) {

  }
if(action.payload && action.payload.status === 403) {
  
  // Only proceed with validation if the steps are not completed and the user is authenticated
  
  if (isAuthenticated && !mandatoryStepsCompleted) {
   try {
    
     dispatch(setIsLoading(true));
     
     const accessToken = state.auth.accessToken;
     const res = await dispatch(validateMandotrySteps({ api, accessToken }));

     const stepsCompleted = res.payload?.data?.mandatory_steps_completed;
     const stepUrl = res.payload?.data?.Step?.step_url;
     dispatch(setmandatoryStep(res.payload?.data?.Step?.is_mandatory));
     dispatch(setmandatoryStepsCompleted(stepsCompleted));
     dispatch(setIsLoading(false));

     if (stepsCompleted) {
       localStorage.setItem('mandatoryStepsCompleted', JSON.stringify(true));
       mandatoryStepsCompleted = true; 
     } else {
       window.location.href = stepUrl||"/login"; 
       return; 
     }
   } catch (error) {
    // console.log(error)
    window.location.href = "/login"; 
   }
    
  }else{
    window.location.href = "/login"
  }

}

  // Proceed with the next action
  return next(action);
};
