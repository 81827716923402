import { ReactComponent as BussinessTravel } from "../../app/assets/BussinessTravel.svg";
import { ReactComponent as EmployeeCommute } from "../../app/assets/EmployeeCommuting.svg";
import { ReactComponent as FuelAndEnergy } from "../../app/assets/Energy.svg";
import { ReactComponent as LockIcon } from "../../app/assets/LockIcon.svg";
import { ReactComponent as Category4Icon } from "../../app/assets/Category4.svg";
import { ReactComponent as Endtoendguarantee } from "../../app/assets/Endtoendguarantee.svg";
import { ReactComponent as WareHouseIcon } from "../../app/assets/WareHouseIcon.svg";
import { ReactComponent as RecyclingIcon } from "../../app/assets/RecyclingIcon.svg";
import { ReactComponent as Category1Icon } from "../../app/assets/RowMaterial.svg";
import { ReactComponent as Category2Icon } from "../../app/assets/Capitalventure.svg";
import { ReactComponent as FranchisesIcon } from "../../app/assets/Franchise.svg";
import { ReactComponent as EnergyIcon } from "../../app/assets/EnergyIcon.svg";
import { ReactComponent as FuelIcon } from "../../app/assets/FuelIcon.svg";
import { ReactComponent as ElectricityIcon } from "../../app/assets/ElectricityIcon.svg";
import { ReactComponent as Emissions } from "../../app/assets/GreenhouseEffect.svg";
import { ReactComponent as DownArrow } from "../../app/assets/DownArrow.svg";
import { ReactComponent as GeneralInfo } from "../../app/assets/GeneralInfo.svg";
import { ReactComponent as BRSRIcon } from "../../app/assets/BRSRIcon.svg";
import { ReactComponent as ReportIcon } from "../../app/assets/ReportIcon.svg";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelabelingData } from "../../features/common/commonThunk";
import { isTokenValid, parseJwt } from "./parseJwt";

import useApi from "../../customHooks/useApi";
import { routePermissions } from "../../app/routePermissions";
import { hasAccess } from "../../app/permissionService";
import { jwtDecode } from "jwt-decode";
const SidebarMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenS3, setIsOpenS3] = useState(false);
  const [isOpenS3Analyze, setIsOpenS3Analyze] = useState(false);
  const [isOpenEnergy, setIsOpenEnergy] = useState(false);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [isOpenReport2, setIsOpenReport2] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [role, setRole] = useState("");
  // const [logoPath, setLogoPath] = useState({});
  const { logoPath } = useSelector((state) => state.common);
  
  const {
    isAuthenticated,
    accessToken: token,
    role_type,
  } = useSelector((state) => state.auth);
  const api = useApi();

  // Extract permissions and role from the token
  useEffect(() => {
    if (isAuthenticated && token) {
      const decodedToken = jwtDecode(token);
      const userPermissions = decodedToken.member?.access || {};
      const userRole = decodedToken.member?.role_type?.toLowerCase() || "";
      setPermissions(userPermissions);
      setRole(userRole);
    }
  }, [isAuthenticated, token]);
  // Function to set the favicon
  function setFavicon(url) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = url;
  }

  // Fetch whitelabeling data and set favicon and title
  useEffect(() => {
    const loadLogo = async () => {
      const result = await dispatch(fetchWhitelabelingData({ api }));
    };
    if (isAuthenticated) {
      loadLogo();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (logoPath) {
      document.title =
        logoPath?.software_name || window.location.hostname.split(".")[0];
      setFavicon(logoPath?.software_fav_icon);
    }
  }, [logoPath]);
  // Check for valid token and redirect if invalid
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      if (!isTokenValid(accessToken)) {
        navigate("/login");
      } else {
        let result = parseJwt(accessToken);
      }
    } else {
      navigate("/login");
    }
  }, []);

  // Inject Freshworks scripts
  useEffect(() => {
    // Create a container div for the scripts
    const scriptContainer = document.getElementById("script-container");

    const script1 = document.createElement("script");
    script1.innerHTML = `
      window.fwSettings = {
        'widget_id': 1070000001434
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();
    `;
    scriptContainer.appendChild(script1);

    // Adding the second script
    const script2 = document.createElement("script");
    script2.src = "https://ind-widget.freshworks.com/widgets/1070000001434.js";
    script2.async = true;
    script2.defer = true;
    scriptContainer.appendChild(script2);

    // Cleanup function to remove the scripts when the component unmounts
    return () => {
      scriptContainer.removeChild(script1);
      scriptContainer.removeChild(script2);
    };
  }, []);
  // Function to check if the user has access to a particular path
  function checkAccess(path) {
    if (isAuthenticated) {
      const routePermission = routePermissions[path];

      if (routePermission) {

        const { type, module, submodule } = routePermission;

        if (type && module && submodule) {
          return hasAccess(role, type, module, submodule, permissions);
        }
      }
      // If no routePermission defined, assume access is denied
      return false;
    }
    // If not authenticated, deny access
    return false;
  }
  function hasCommonElement(arr1, arr2) {
    
    return arr1.some(element => arr2.includes(element));
  }
  // Define the menu items and their structure
  const menuItems = [
    {
      title: "DATA INPUT",
      showTitle:(permissions?.admin?.is_enabled||permissions?.data_entry?.is_enabled)&&hasCommonElement(permissions.modules,[1,2,3,-1]),
      items: [
        {
          name: "General Info",
          path: "/general-info",
          icon: <GeneralInfo className="w-4 h-4 flex flex-shrink-0" />,
          // Assuming General Info is accessible to all
          alwaysVisible: false,
        },
        {
          name: "Energy",
          icon: <EnergyIcon className="w-4 h-4 flex flex-shrink-0" />,
          submenuToggle: () => setIsOpenEnergy(!isOpenEnergy),
          isOpen: isOpenEnergy,
          subItems: [
            {
              name: "Fuel",
              path: "/energy/fuel",
              icon: <FuelIcon className="w-4 h-4 flex flex-shrink-0" />,
            },
            {
              name: "Electricity",
              path: "/energy/electricity",
              icon: <ElectricityIcon className="w-4 h-4 flex flex-shrink-0" />,
            },
          ],
        },
        {
          name: "Scope 3",
          icon: (
            <span className="w-3 h-3 p-1 rounded-sm text-black bg-white flex flex-shrink-0 justify-center items-center">
              3
            </span>
          ),
          submenuToggle: () => setIsOpenS3(!isOpenS3),
          isOpen: isOpenS3,
          subItems: [
            {
              name: "Purchased Goods and Services",
              path: "/scope3/category-1",
              icon: (
                <Category1Icon className="w-4 h-4 flex flex-shrink-0 fill-current" />
              ),
            },
            {
              name: "Capital Goods",
              path: "/scope3/category-2",
              icon: (
                <Category2Icon className="w-4 h-4 flex flex-shrink-0 fill-white" />
              ),
            },
            {
              name: "Upstream Transportation and Distribution",
              path: "/scope3/category-4",
              icon: <Category4Icon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Waste from Operations",
              path: "/scope3/category-5",
              icon: <RecyclingIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Business Travel",
              path: "/scope3/category-6",
              icon: (
                <BussinessTravel className="w-4 h-4 flex flex-shrink-0 fill-current" />
              ),
            },
            {
              name: "Employee Commute",
              path: "/scope3/category-7",
              icon: (
                <EmployeeCommute className="w-4 h-4 flex flex-shrink-0 fill-current" />
              ),
            },
            {
              name: "Downstream Transportation and Distribution",
              path: "/scope3/category-9",
              icon: <Category4Icon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "End-of-life treatment of sold products",
              path: "/scope3/End-of-life treatment of sold products",
              icon: <Endtoendguarantee className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Downstream leased assets",
              path: "/scope3/Downstream leased assets",
              icon: <WareHouseIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
          ],
        },
      ],
    },
    {
      title: "ANALYZE",
      showTitle:(permissions?.admin?.is_enabled||permissions?.visualization?.is_enabled)&&hasCommonElement(permissions.modules,[1,2,3,-1]),
      items: [
        {
          name: "Emissions",
          path: "/energy/emissions",
          icon: <Emissions className="w-4 h-4 flex flex-shrink-0" />,
          // alwaysVisible: true,
        },
        {
          name: "Scope 1",
          path: "/energy/fuelAnalyze/scope1",
          icon: (
            <span className="w-3 h-3 p-1 rounded-sm text-black bg-white flex flex-shrink-0 justify-center items-center">
              1
            </span>
          ),
        },
        {
          name: "Scope 2",
          path: "/energy/fuelAnalyze/scope2",
          icon: (
            <span className="w-3 h-3 p-1 rounded-sm text-black bg-white flex flex-shrink-0 justify-center items-center">
              2
            </span>
          ),
        },
        {
          name: "Scope 3",
          icon: (
            <span className="w-3 h-3 p-1 rounded-sm text-black bg-white flex flex-shrink-0 justify-center items-center">
              3
            </span>
          ),
          submenuToggle: () => setIsOpenS3Analyze(!isOpenS3Analyze),
          isOpen: isOpenS3Analyze,
          subItems: [
            {
              name: "Purchased Goods and Services",
              path: "/scope3/Analyze/category-1",
              icon: (
                <Category1Icon className="w-4 h-4 flex flex-shrink-0 fill-current" />
              ),
            },
            {
              name: "Capital Goods",
              path: "/scope3/Analyze/category-2",
              icon: (
                <Category2Icon className="w-4 h-4 flex flex-shrink-0 fill-white" />
              ),
            },
            {
              name: "Fuel and Energy related activities",
              path: "/scope3/Analyze/Fuel and Energy related activities",
              icon: <FuelAndEnergy className="w-4 h-4 flex flex-shrink-0 fill-white" />,
            },
            {
              name: "Upstream Transportation and Distribution",
              path: "/scope3/Analyze/category-4",
              icon: <Category4Icon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Waste from Operations",
              path: "/scope3/Analyze/category-5",
              icon: <RecyclingIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Business Travel",
              path: "/scope3/Analyze/category-6",
              icon: (
                <BussinessTravel className="w-4 h-4 flex flex-shrink-0 fill-current" />
              ),
            },
            {
              name: "Employee Commute",
              path: "/scope3/Analyze/category-7",
              icon: (
                <EmployeeCommute className="w-4 h-4 flex flex-shrink-0 fill-current" />
              ),
            },
            {
              name: "Upstream leased assets",
              path: "/scope3/Analyze/Upstream leased assets",
              icon: <WareHouseIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Downstream Transportation and Distribution",
              path: "/scope3/Analyze/category-9",
              icon: <Category4Icon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "End-of-life treatment of sold products",
              path: "/scope3/Analyze/End-of-life treatment of sold products",
              icon: <Endtoendguarantee className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Downstream leased assets",
              path: "/scope3/Analyze/Downstream leased assets",
              icon: <WareHouseIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
            {
              name: "Franchises",
              path: "/scope3/Analyze/Franchises",
              icon: <FranchisesIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
            },
          ],
        },
        {
          name: "Waste",
          path: "/scope3/Analyze/waste-material",
          icon: <RecyclingIcon className="fill-current" />,
        },
      ],
    },
    {
      title: "REPORT",
      showTitle:(permissions?.admin?.is_enabled||permissions?.reporting?.is_enabled)&&hasCommonElement(permissions.modules,[4,5,-1]),
      items: [
        {
          name: "Custom reports",
          icon:<ReportIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
          submenuToggle: () => setIsOpenReport(!isOpenReport),
          isOpen: isOpenReport,
          subItems: [
            {
              name: "Custom reports",
              path: "/custom-report",
              icon: <BRSRIcon className="w-4 h-4 flex flex-shrink-0" />,
              // alwaysVisible: false,
            },
            {
              name: "Green reports",
              path: "/green-report",
              icon: <BRSRIcon className="w-4 h-4 flex flex-shrink-0" />,
              // alwaysVisible: false,
            },
          ],
        },
        {
          name: "Framework Based Reports",
          icon:<ReportIcon className="w-4 h-4 flex flex-shrink-0 fill-current" />,
          submenuToggle: () => setIsOpenReport2(!isOpenReport2),
          isOpen: isOpenReport2,
          subItems: [
            {
              name: "BRSR",
              path: "/BRSR",
              icon: <BRSRIcon className="w-4 h-4 flex flex-shrink-0" />,
              // alwaysVisible: false,
            },
            {
              name: "GRI",
              path: "/GRI",
              icon: <BRSRIcon className="w-4 h-4 flex flex-shrink-0" />,
              // alwaysVisible: false,
            },
            {
              name: "CDP",
              path: "/CDP",
              icon: <BRSRIcon className="w-4 h-4 flex flex-shrink-0" />,
              // alwaysVisible: false,
            },
          ],
        },
        
      ],
    },
    {
      title: null,
      showTitle: false,
      items: [
        // {
        //   // Assuming REDUCE is a menu with possible submenus or direct link
        //   name: "REDUCE",
        //   path: "/reduce",
        //   icon: null, // Add icon if available
        //   alwaysVisible: false,
        // },
      ],
    },
    {
      title: null,
      showTitle: false,
      items: [
        // {
        //   // Assuming REDUCE is a menu with possible submenus or direct link
        //   name: "OFFSET",
        //   path: "/offset",
        //   icon: null, // Add icon if available
        //   alwaysVisible: false,
        // },
      ],
    },
  ];
  // Helper function to determine if any subItems are accessible
  const hasAccessibleSubItems = (subItems) => {
    for (let item of subItems) {
      if (item.subItems) {
        if (hasAccessibleSubItems(item.subItems)) {
          return true;
        }
      } else if (item.path) {
        if (checkAccess(item.path)) {
          return true;
        }
      } else if (item.alwaysVisible) {
        return true;
      }
    }
    return false;
  };
  // console.log({menuItems})
  return (
    <>
      <aside
        style={{
          scrollbarWidth: "none" /* For Firefox */,
          WebkitScrollbar: {
            width: 0,
            background: "transparent",
          },
        }}
        id="script-container"
        className="flex flex-col justify-between w-full h-screen bg-black text-white overflow-y-auto"
      >
        <span className="flex items-center py-4 px-4 text-xs justify-center h-28">
          <div className="px-2">
            {logoPath?.software_text_style_logo ? (
              <img
                src={logoPath?.software_text_style_logo}
                className="h-auto object-contain bg-current"
                alt="logo"
              />
            ) : (
              ""
            )}
          </div>
        </span>
        <div className="w-full px-1 flex-1">
          {menuItems.map((menuSection, index) => (
            <div key={index}>
              {menuSection.title && menuSection.showTitle && (
                <span className="flex gap-2 items-center py-2 px-6 text-xs text-white font-bold ">
                  {menuSection.title}
                </span>
              )}
              {menuSection.items &&
                menuSection.items.map((menuItem, idx) => {
                  // Check access for items with path
                  if (menuItem.path) {
                    
                    if (menuItem.alwaysVisible || checkAccess(menuItem.path)) {
                      return (
                        <NavLink
                          key={idx}
                          to={menuItem.path}
                          className="flex gap-2 items-center py-4 px-6 text-xs hover:bg-[#02AB6C]"
                          style={({ isActive }) => ({
                            background: isActive ? "#02AB6C" : "",
                          })}
                        >
                          {menuItem.icon}
                          {menuItem.name}
                        </NavLink>
                      );
                    } else {
                      return null;
                    }
                  } else if (menuItem.subItems) {
                    // For items with submenus

                    const accessible = hasAccessibleSubItems(menuItem.subItems);

                    if (accessible) {
                      return (
                        <div key={idx}>
                          <span
                            onClick={menuItem.submenuToggle}
                            className="flex gap-2 items-center py-4 px-6 text-xs hover:bg-[#02AB6C] cursor-pointer"
                          >
                            {menuItem.icon}
                            {menuItem.name}
                            <DownArrow
                              className={`ml-3 ${
                                menuItem.isOpen
                                  ? "transform rotate-180"
                                  : "transform rotate-0"
                              } transition-all duration-300 ease-in-out`}
                            />
                          </span>
                          {menuItem.isOpen && (
                            <div className="">
                              {menuItem.subItems.map((subItem, subIdx) => {
                                if (subItem.path) {
                                  if (checkAccess(subItem.path)) {
                                    return (
                                      <NavLink
                                        key={subIdx}
                                        to={subItem.path}
                                        className="flex gap-2 items-center py-4 px-6 text-xs hover:bg-[#02AB6C]"
                                        style={({ isActive }) => ({
                                          background: isActive ? "#02AB6C" : "",
                                        })}
                                      >
                                        {subItem.icon}
                                        {subItem.name}
                                      </NavLink>
                                    );
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return null;
                                }
                              })}
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  } else if (
                    menuItem.name === "REDUCE" &&
                    menuItem.alwaysVisible
                  ) {
                    // Handle REDUCE menu item

                    if (menuItem.path) {
                      if (!checkAccess(menuItem.path)) {
                        //till now access always
                        return (
                          <NavLink
                            key={idx}
                            to={menuItem.path}
                            className="flex gap-2 items-center py-4 px-6 text-xs hover:bg-[#02AB6C] font-bold"
                            style={({ isActive }) => ({
                              background: isActive ? "#02AB6C" : "",
                            })}
                          >
                            {menuItem.icon}
                            {menuItem.name}
                          </NavLink>
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })}
              <hr className="opacity-50" />
            </div>
          ))}
        </div>
        <div className="h-auto">
          <span className="flex gap-2 items-center py-3 shrink-0 text-xs bg-[#02AB6C] ">
            <span className="ml-2">Powered by </span>
            <span className="text-xl capitalize">
              {logoPath?.partner_name || ""}
            </span>
          </span>
        </div>
      </aside>
    </>
  );
};
export default SidebarMenu;
