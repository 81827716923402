import React from 'react';

const FormGroup = ({
  formValue,
  treatmentMechanismOptions,
  wasteMaterialOptions,
  handleFormChange,
  currencyOptions,
  unitOptions,
  siteData,
  index,
  handleRemove
}) => (
  <div className="relative grid grid-cols-4 gap-4 px-8 mb-4 mx-4 justify-around border rounded-md p-4">
    <button
      type="button"
      className="absolute top-2 right-2 text-red-500"
      onClick={() => handleRemove(index)}
    >
      Remove
    </button>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_period_start_${index}`} className="text-xs py-1.5">
        Period Start<span className="text-red-500">*</span>
      </label>
      <input
        max={new Date().toISOString().split("T")[0]}
        type="date"
        name="treated_period_start"
        id={`treated_period_start_${index}`}
        value={formValue.treated_period_start || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        required
      />
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_period_end_${index}`} className="text-xs py-1.5">
        Period End<span className="text-red-500">*</span>
      </label>
      <input
        max={new Date().toISOString().split("T")[0]}
        type="date"
        name="treated_period_end"
        id={`treated_period_end_${index}`}
        value={formValue.treated_period_end || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        min={formValue.treated_period_start}
        required
      />
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_site_id_${index}`} className="text-xs py-1.5">
        Site<span className="text-red-500">*</span>
      </label>
      <select
        name="treated_site_id"
        id={`treated_site_id_${index}`}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        required
        value={formValue.treated_site_id || ""}
      >
        <option value="" disabled>
          Choose the site
        </option>
        {siteData &&
          siteData.map((site) => (
            <option key={site.id} value={site.id}>
              {site.site}
            </option>
          ))}
      </select>
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`waste_material_id_${index}`} className="text-xs py-1.5">
        Waste Material<span className="text-red-500">*</span>
      </label>
      <select
        name="waste_material_id"
        id={`waste_material_id_${index}`}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        required
        value={formValue.waste_material_id || ""}
      >
        <option value="" disabled>
          Choose the waste material
        </option>
        {wasteMaterialOptions &&
          wasteMaterialOptions.map((material) => (
            <option key={material.id} value={material.id}>
              {material.waste_material}
            </option>
          ))}
      </select>
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treatment_mechanism_id_${index}`} className="text-xs py-1.5">
        Treatment Mechanism<span className="text-red-500">*</span>
      </label>
      <select
        name="treatment_mechanism_id"
        id={`treatment_mechanism_id_${index}`}
        value={formValue.treatment_mechanism_id || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        required
      >
        <option value="" >
          Choose the treatment mechanism
        </option>
        {treatmentMechanismOptions &&
          treatmentMechanismOptions.filter(i=>formValue.waste_material_id ==i.waste_material_id ).map((mechanism) => (
            <option key={mechanism.id} value={mechanism.waste_treatment_mechanism_id}>
              {mechanism.waste_treatment_mechanism}
            </option>
          ))}
      </select>
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_proportion_of_waste_${index}`} className="text-xs py-1.5">
        Proportion of Waste Treated<span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        name="treated_proportion_of_waste"
        id={`treated_proportion_of_waste_${index}`}
        value={formValue.treated_proportion_of_waste || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        placeholder="Type the proportion"
        required
      />
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_quantity_${index}`} className="text-xs py-1.5">
        Treated Quantity
      </label>
      <input
        type="number"
        name="treated_quantity"
        id={`treated_quantity_${index}`}
        value={formValue.treated_quantity || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        placeholder="Type the quantity"
        min="0"
      />
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_unit_id_${index}`} className="text-xs py-1.5">
        Unit
      </label>
      <select
        name="treated_unit_id"
        id={`treated_unit_id_${index}`}
        value={formValue.treated_unit_id || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
      >
        <option value="" disabled>
          Choose the unit
        </option>
        {unitOptions &&
          unitOptions.map((unit) => (
            <option key={unit.id} value={unit.id}>
              {unit.unit}
            </option>
          ))}
      </select>
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_amount_spent_${index}`} className="text-xs py-1.5">
        Amount Spent<span className="text-red-500">*</span>
      </label>
      <input
        type="number"
        name="treated_amount_spent"
        id={`treated_amount_spent_${index}`}
        value={formValue.treated_amount_spent || ""}
        onChange={(e) => handleFormChange(e, index)}
        min="0"
        step="0.01"
        placeholder="Type the amount"
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        required
      />
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_currency_id_${index}`} className="text-xs py-1.5">
        Currency<span className="text-red-500">*</span>
      </label>
      <select
        name="treated_currency_id"
        id={`treated_currency_id_${index}`}
        value={formValue.treated_currency_id || ""}
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
        required
      >
        <option value="" disabled>
          Choose the currency
        </option>
        {currencyOptions &&
          currencyOptions.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.currency}
            </option>
          ))}
      </select>
    </div>
    <div className="col-span-1 flex flex-col">
      <label htmlFor={`treated_evidence_${index}`} className="text-xs py-1.5">
        Evidence <span className="text-red-500">*</span>
      </label>
      <input
        type="file"
        name="treated_evidence"
        id={`treated_evidence_${index}`}
        multiple
        accept=".jpg, .jpeg, .png, .pdf, .zip"
        onChange={(e) => handleFormChange(e, index)}
        className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
      />
    </div>
  </div>
);

export default FormGroup;
