import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as FilterMenuIcon } from "../../app/assets/FilterMenuIcon.svg";
import { ReactComponent as ResetIcon } from "../../app/assets/ResetIcon.svg";

const FilterComponent = ({ filters, onFilterChange }) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    
    const initialFilters = {};
    filters.forEach((filter) => {
      const value = searchParams.get(filter.name);
      if (value) {
        initialFilters[filter.name] = value;
      }
    });
    setSelectedFilters(initialFilters);
    onFilterChange(initialFilters);
  }, []);
 
  const handleChange = (filterName, value) => {
    const updatedFilters = {
      ...selectedFilters,
      [filterName]: value,
    };

    setSelectedFilters(updatedFilters);

    // Update URL parameters
    const newParams = new URLSearchParams();
    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key]) {
        newParams.set(
          key,
          key === "date" ? updatedFilters[key] : updatedFilters[key]
        );
      }
    });
    filters.forEach((filter) => {
      // console.log(newParams)
      if (!updatedFilters[filter.name] && searchParams.has(filter.name)) {
        newParams.delete(filter.name);
      }
    });
    // console.log(newParams)
    setSearchParams(newParams);
    onFilterChange(updatedFilters);
  };
  const handleClearFilters = () => {
    // Clear filters
    setSelectedFilters({});

    // Clear URL parameters
    setSearchParams(new URLSearchParams());

    // Notify parent component
    onFilterChange({});
  };
  return (
    <div className="flex justify-end gap-2 items-end">
      <FilterMenuIcon className="cursor-pointer mb-2" />
    

      {filters.map((filter) => (
        <div
          key={filter.name}
          className="flex flex-col justify-center items-start"
        >
          <label className="block text-sm font-medium text-gray-700">
            {filter.label}
          </label>
          {filter.type === "date" ? (
            <input
              type="date"
              onChange={(e) => handleChange(filter.name, e.target.value)}
              value={selectedFilters[filter.name] || ""}
              className="w-full py-1 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 text-sm"
            />
          ) : (
            <select
              style={{ width: "150px" }}
              value={selectedFilters[filter.name] || ""}
              onChange={(e) => handleChange(filter.name, e.target.value)}
              className="block w-full py-1 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 text-sm"
            >
              <option value="">All</option>
              {filter?.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        </div>
      ))}
      {Object.values(selectedFilters).length > 0 && (
        <span
          className="font-light text-blue-500 underline cursor-pointer"
          onClick={handleClearFilters}
        >
          <ResetIcon/>
        </span>
      )}
    
    </div>
  );
};

export default FilterComponent;
