// import { ReactComponent as CloseIcon } from "../../../app/assets/CloseIcon.svg";
// import { ReactComponent as InfoIcon } from "../../../app/assets/InfoIcon.svg";
// import { useDispatch, useSelector } from "react-redux";

// import { useState } from "react";

// import { toast } from "react-toastify";
// import useApi from "../../../customHooks/useApi";

// import { postscope3Category5Data, scope3Category5UploadEvidence } from "../../../features/scope3/category5/Scope3Category5Thunk";

// const Category5WasteTreatedForm = ({
//   handleFormChange,
//   closePurchasePopupForm,
//   files,
//   startDate,
// }) => {
//   let formValue = useSelector((state) =>state.scope3Category5.scope3Category5Form);
//   const api = useApi();

//   const loading = useSelector((state) => state.common.isLoading);
//   const dispatch = useDispatch();

//   const currencyOptions = useSelector(
//     (state) => state.scope3Category5.currencyData
//   );
//   const unitOptions = useSelector((state) => state.scope3Category5.unitData);

//   const siteData = useSelector((state) => state.scope3Category5.siteData);

//   const handlePurchaseFormSubmit = (e) => {
//     e.preventDefault();
//     const form = document.querySelector("#purchase-Scope3Category5-form");
//     if (form.length && form.checkValidity()) {
//       if (files) {
//         dispatch(scope3Category5UploadEvidence({ api, files }))
//           .then((result) => {
//             if (result?.payload?.msg === "File uploaded.") {
//               dispatch(postscope3Category5Data({ api }));
//               closePurchasePopupForm();
//             }
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       } else {
//         toast.warning("Please upload evidence");
//       }
//     } else if (form.length) {
//       form.querySelector('input[type="submit"]').click();
//     }
//   };

//   return (
//     <>

//         <div className="flex flex-col justify-center items-center  rounded-t-md text-xs">

//           <form
//             style={{
//               opacity: loading ? 0.5 : 1,
//               pointerEvents: loading ? "none" : "auto",
//             }}
//             id="purchase-Scope3Category5-form"
//             className="flex flex-col w-full py-4"
//             onSubmit={handlePurchaseFormSubmit}
//           >
//             <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="period_start" className="text-xs py-1.5">
//                   Period Start<span className="text-red-500">*</span>
//                 </label>
//                 <input
//                   max={new Date().toISOString().split("T")[0]}
//                   type="date"
//                   name="period_start"
//                   id="period_start"
//                   value={formValue.period_start || ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   required
//                 />
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="period_end" className="text-xs py-1.5">
//                   Period End<span className="text-red-500">*</span>
//                 </label>
//                 <input
//                   max={new Date().toISOString().split("T")[0]}
//                   type="date"
//                   name="period_end"
//                   id="period_end"
//                   value={formValue.period_end || ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   min={startDate}
//                   required
//                 />
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="site" className="text-xs py-1.5">
//                   Site<span className="text-red-500">*</span>
//                 </label>
//                 <select
//                   name="site"
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   required
//                   value={formValue.site || ""}
//                 >
//                   <option value="" disabled>
//                     Choose the site
//                   </option>
//                   {siteData &&
//                     siteData.map((site, index) => (
//                       <option key={site.id} value={site.id}>
//                         {site.site}
//                       </option>
//                     ))}
//                 </select>
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="waste_material" className="text-xs py-1.5">
//                   Waste Material<span className="text-red-500">*</span>
//                 </label>
//                 <select
//                   name="waste_material"
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   required
//                   value={formValue.waste_material || ""}
//                 >
//                   <option value="" disabled>
//                     Choose the waste material
//                   </option>
//                   <option value="Plastic">Plastic</option>
//                   <option value="eWaste">eWaste</option>

//                 </select>
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="treatment_mechanism" className="text-xs py-1.5">
//                   Treatment Mechanism<span className="text-red-500">*</span>
//                 </label>
//                 <input
//                   type="text"
//                   name="treatment_mechanism"
//                   value={formValue.treatment_mechanism || ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   placeholder="Type the mechanism"
//                   required
//                 />
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="proportion_of_waste_treated" className="text-xs py-1.5">
//                   Proportion of Waste Treated<span className="text-red-500">*</span>
//                 </label>
//                 <input
//                   type="text"
//                   name="proportion_of_waste_treated"
//                   value={formValue.proportion_of_waste_treated || ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   placeholder="Type the proportion"
//                   required
//                 />
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="treated_quantity" className="text-xs py-1.5">
//                   Treated Quantity
//                 </label>
//                 <input
//                   type="number"
//                   name="treated_quantity"
//                   value={formValue.treated_quantity || ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   placeholder="Type the quantity"
//                   min="0"
//                 />
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="unit" className="text-xs py-1.5">
//                   Unit
//                 </label>
//                 <select
//                   name="unit"
//                   value={formValue.unit|| ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 >
//                   <option value="" disabled>
//                     Choose the unit
//                   </option>
//                   {unitOptions &&
//                     unitOptions.map((unit, index) => (
//                       <option key={unit.id} value={unit.id}>
//                         {unit.unit}
//                       </option>
//                     ))}
//                 </select>
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="amount_spent" className="text-xs py-1.5">
//                   Amount Spent<span className="text-red-500">*</span>
//                 </label>
//                 <input
//                   type="number"
//                   name="amount_spent"
//                   value={formValue.amount_spent || ""}
//                   onChange={handleFormChange}
//                   min="0"
//                   step="0.01"
//                   placeholder="Type the amount"
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   required
//                 />
//               </div>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="currency" className="text-xs py-1.5">
//                   Currency<span className="text-red-500">*</span>
//                 </label>
//                 <select
//                   name="currency"
//                   value={formValue.currency || ""}
//                   onChange={handleFormChange}
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                   required
//                 >
//                   <option value="" disabled>
//                     Choose the currency
//                   </option>
//                   {currencyOptions.map((currency, index) => (
//                     <option key={index} value={currency.id}>
//                       {currency.currency}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <button>Add new</button>
//               <div className="col-span-1 flex flex-col">
//                 <label htmlFor="evidence" className="text-xs py-1.5">
//                   Evidence <span className="text-red-500">*</span>
//                 </label>
//                 <input
//                   disabled={loading}
//                   type="file"
//                   name="evidence"
//                   multiple
//                   accept=".jpg, .jpeg, .png, .pdf, .zip"
//                   onChange={handleFormChange}
//                   required
//                   className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 />
//               </div>
//             </div>

//             <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
//               <div className="w-1/6 flex flex-col">
//                 <button
//                   disabled={loading}
//                   type="button"
//                   className="py-2 px-6 text-green-600 text-sm rounded-md"
//                   onClick={() => closePurchasePopupForm()}
//                 >
//                   Cancel
//                 </button>
//               </div>
//               <div className="w-1/6 flex flex-col">
//                 <button
//                   type="submit"
//                   disabled={loading}
//                   className={`py-2 px-6 text-white text-sm ${
//                     loading ? "bg-green-500 cursor-wait" : "bg-green-600"
//                   } rounded-md`}
//                 >
//                   Submit
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>

//     </>
//   );
// };

// export default Category5WasteTreatedForm;

import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";

import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";

import {
  postscope3Category5WasteTreatedData,
  scope3Category5UploadEvidence,
} from "../../../features/scope3/category5/Scope3Category5Thunk";

import FormGroup from "./FormGroup";
import { setscope3Category5WasteTreatedForm } from "../../../features/scope3/category5/Scope3Category5Slice";
import { calculateDaysBetween, calculateTreatedQuantity } from "./process";

const Category5WasteTreatedForm = ({ closePurchasePopupForm }) => {
  const api = useApi();
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  const [files,setFiles]=useState(null)
  const currencyOptions = useSelector(
    (state) => state.scope3Category5.currencyData
  );
  const unitOptions = useSelector((state) => state.scope3Category5.unitData);
  const siteData = useSelector((state) => state.scope3Category5.siteData);

  const initialFormValue = {
    treated_period_start: "",
    treated_period_end: "",
    treated_site_id: "",
    waste_material: "",
    waste_material_id: "",
    treated_treatment_mechanism: "",
    treatment_mechanism_id: "",
    treated_proportion_of_waste: "",
    treated_quantity: "",
    treated_unit_id: "",
    treated_amount_spent: "",
    treated_currency_id: "",
    treated_evidence: null,
  };

  const [formValues, setFormValues] = useState([initialFormValue]);
  let { scope3Category5WasteProduceForm, treatmentMechanism, wasteMaterial,scope3Category5WasteTreatedForm,wasteTreatmentMapping} =
    useSelector((state) => state.scope3Category5);
  const { treated_period_start, treated_period_end } =
    scope3Category5WasteProduceForm;
  const totalDays = calculateDaysBetween(
    treated_period_start,
    treated_period_end
  );
  const handleFormChange = async(e, index) => {
    const { name, value, files } = e.target;
  
    // Copy the current form values
    const updatedFormValues = [...formValues];
  
    // Determine the new values for waste_material and treated_treatment_mechanism
    const currentWasteMaterial = updatedFormValues[index].waste_material || "";
    const currentTreatedTreatmentMechanism = updatedFormValues[index].treated_treatment_mechanism || "";
  
    let waste_material = currentWasteMaterial;
    let treated_treatment_mechanism = currentTreatedTreatmentMechanism;
  
    if (name === "waste_material_id") {
      const selectedWasteMaterial = wasteMaterial.find((item) => item.id ==value);
      waste_material = selectedWasteMaterial ? selectedWasteMaterial.waste_material : "";
    }
  
    if (name === "treatment_mechanism_id") {
      const selectedTreatedMaterial = treatmentMechanism.find((item) => item.id == value);
      treated_treatment_mechanism = selectedTreatedMaterial ? selectedTreatedMaterial.waste_treatment_mechanism : "";
    }
  if(name==="treated_evidence"){
    const selectedFiles =files;
        try {
          const res = await dispatch(scope3Category5UploadEvidence({ api, files: selectedFiles }));
          if (res?.payload?.path) {
            updatedFormValues[index] = {
              ...updatedFormValues[index],
            treated_evidence: res.payload.path,
            };
            setFiles(selectedFiles)
          } else {
            toast.error("Failed to upload evidence");
          }
        } catch (error) {
          console.error("File upload error:", error);
        }
  }
    updatedFormValues[index] = {
      ...updatedFormValues[index],
      [name]: value,
      waste_material,
      treated_treatment_mechanism
    };
  
    // Handle calculation if date fields are updated
    if (name === "treated_period_start" || name === "treated_period_end") {
      if (
        updatedFormValues[index].treated_period_start &&
        updatedFormValues[index].treated_period_end
      ) {
        const days = calculateDaysBetween(
          updatedFormValues[index].treated_period_end,
          updatedFormValues[index].treated_period_start
        );
        updatedFormValues[index]["treated_quantity"] = calculateTreatedQuantity(
          days,
          totalDays
        ).toFixed(2);
      }
    }
  
    setFormValues(updatedFormValues);
    dispatch(setscope3Category5WasteTreatedForm(updatedFormValues));
  };
  
  const handleAddNew = () => {
    setFormValues([...formValues, initialFormValue]);
  };

  const handleRemove = (index) => {
    if (formValues.length > 1) {
      setFormValues(formValues.filter((_, i) => i !== index));
    }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector("#purchase-Scope3Category5-form");
    if (form.length && form.checkValidity()) {
      if(files){
        dispatch(postscope3Category5WasteTreatedData({ api, i:1, formValues }));
      
      closePurchasePopupForm();

      }else{
        toast.error("Please select evidence")
      }
    } else if (form.length) {
      form.querySelector('input[type="submit"]').click();
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center rounded-t-md text-xs">
        <form
          style={{
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          id="purchase-Scope3Category5-form"
          className="flex flex-col w-full py-4"
          onSubmit={handlePurchaseFormSubmit}
        >
          {formValues.map((formValue, index) => (
            <FormGroup
              treatmentMechanismOptions={wasteTreatmentMapping}
              wasteMaterialOptions={wasteMaterial}
              key={index}
              formValue={formValue}
              handleFormChange={handleFormChange}
              handleRemove={handleRemove}
              currencyOptions={currencyOptions}
              unitOptions={unitOptions}
              siteData={siteData}
              index={index}
            />
          ))}
          <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
            <button
              type="button"
              onClick={handleAddNew}
              className="py-2 px-6 text-green-600 text-sm rounded-md"
            >
              Add new
            </button>
            <div className="w-1/6 flex flex-col">
              <button
                disabled={loading}
                type="button"
                className="py-2 px-6 text-green-600 text-sm rounded-md"
                onClick={() => closePurchasePopupForm()}
              >
                Cancel
              </button>
            </div>
            <div className="w-1/6 flex flex-col">
              <button
                type="submit"
                disabled={loading}
                className={`py-2 px-6 text-white text-sm ${
                  loading ? "bg-green-500 cursor-wait" : "bg-green-600"
                } rounded-md`}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Category5WasteTreatedForm;
