import { createSlice } from "@reduxjs/toolkit";

// Assume you have thunks for login and logout (similar to the previous example)

const scope3Category5Slice = createSlice({
  name: "Scope3Category5",
  initialState: {
    scope3Category5Records: [

    ],
    scope3Category5WasteProduceForm: {},
    scope3Category5WasteTreatedForm: {},
    scope3Category5InputRecords: [
    ],
    scope3Category5InputRecordsTreated: [

    ],
    wasteMaterial: [
      
    ],
    wasteImpact: [
      {
        id: 1,
        waste_impact: "Hazardous",
      },
      {
        id: 2,
        waste_impact: "Non-Hazardous",
      },
    ],
    filters: null,
    sorting: null,
    treatmentMechanism: [
     
    ],
    wasteTreatmentMapping: [
     
    ],

    scope3Category5RecordType: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
    totalCount: 10,
    siteData: [
     
    ],

    unitData: [
      
    ],
    currencyData: [
      
    ],
  },
  reducers: {
    setscope3Category5Records: (state, action) => {
      state.scope3Category5Records = action.payload.data;
    },
    setscope3Category5InputRecords: (state, action) => {
      state.scope3Category5InputRecords = action.payload;
    },
    setscope3Category5WasteProduceForm: (state, action) => {
      return {
        ...state,
        scope3Category5WasteProduceForm: {
          ...state.scope3Category5WasteProduceForm,
          ...action.payload,
        },
      };
    },
    setscope3Category5WasteTreatedForm: (state, action) => {
      return {
        ...state,
        scope3Category5WasteTreatedForm: {
          ...state.scope3Category5WasteTreatedForm,
          ...action.payload,
        },
      };
    },
    resetscope3Category5WasteProduceForm: (state, action) => {
      state.scope3Category5WasteProduceForm = {};
    },
    resetscope3Category5WasteTreatedForm: (state, action) => {
      state.scope3Category5WasteTreatedForm = {};
    },
    resetscope3Category5Form: (state, action) => {
      state.scope3Category5WasteTreatedForm = {};
      state.scope3Category5WasteProduceForm = {};
    },
    setEvidence: (state, action) => {
      const { type, evidence } = action.payload;
      if (type === "wasteProduce") {
        state.scope3Category5WasteProduceForm.evidence = evidence;
      } else {
        state.scope3Category5WasteTreatedForm.evidence = evidence;
      }
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setwasteImpact: (state, action) => {
      state.wasteImpact = action.payload;
    },
    setTreatmentMechanism: (state, action) => {
      state.treatmentMechanism = action.payload;
    },
    setwasteTreatmentMapping: (state, action) => {
      state.wasteTreatmentMapping = action.payload;
    },

    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
    setwasteMaterial: (state, action) => {
      state.wasteMaterial = action.payload;
    },

    setfiltredwasteMaterial: (state, action) => {
      state.filtredwasteMaterial = action.payload;
    },

    setUnitData: (state, action) => {
      state.unitData = action.payload;
    },
    setCurrencyData: (state, action) => {
      state.currencyData = action.payload;
    },
    setdeletes3c9Data: (state, action) => {
      state.scope3Category5InputRecords =
        state.scope3Category5InputRecords.filter((i) => i.id != action.payload);
    },
  },
});

export const {
  resetscope3Category5WasteProduceForm,
  resetscope3Category5WasteTreatedForm,
  setfiltredwasteMaterial,
  setscope3Category5WasteTreatedForm,
  setdeletes3c9Data,

  setwasteMaterial,
  setFilters,
  setEvidence,
  setscope3Category5WasteProduceForm,

  setTreatmentMechanism,
  setwasteImpact,
  setscope3Category5Records,
  setSorting,
  setCurrentPage,
  setItemsPerPage,

  setTotalCount,
  setTotalPages,
  setCurrencyData,

  setSiteData,
  setUnitData,setwasteTreatmentMapping,
  resetscope3Category5Form,

  setscope3Category5InputRecords,

  setscope3Category5CountryData,
} = scope3Category5Slice.actions;
export default scope3Category5Slice.reducer;
