function getCurrentFinancialYear() {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  return currentMonth <= 3 ? today.getFullYear() - 1 : today.getFullYear();
}
export function processData(data, years, breakDown = "NA") {
  const currentYear = getCurrentFinancialYear();
// console.log({data, years})
  let { Fuel, Electricity } = data || {};

  const breakdownTypes = ["renewable", "non-renewable"];
  const breakdownLower = breakDown.toLowerCase();
  const isValidBreakdown = breakdownTypes.includes(breakdownLower);

  if (isValidBreakdown) {
    Fuel =
      Fuel?.filter(
        (item) => item.source_type?.toLowerCase() === breakdownLower
      ) || [];
    Electricity =
      Electricity?.filter(
        (item) => item.source_type?.toLowerCase() === breakdownLower
      ) || [];
  }
  // console.log({Electricity})
  const dataByYear = (data, year) =>
    data?.filter((item) => Number(item.year) === year) || [];
  const calculateTotalUsage = (data) =>
    data?.reduce((acc, curr) => acc + parseFloat(curr.usage_in_kwh), 0) *
      0.004 || 0;
  const calculateTotalEmission = (data) =>
    data?.reduce((acc, curr) => acc + parseFloat(curr.total_co2e_kg), 0) || 0;

  const results = {};
  years.forEach((year) => {
    const FuelForYear = calculateTotalUsage(dataByYear(Fuel, year));
    const ElectricityForYear = calculateTotalUsage(
      dataByYear(Electricity, year)
    );
    const FuelForYearemission = calculateTotalEmission(dataByYear(Fuel, year));
    const ElectricityForYearemission = calculateTotalEmission(
      dataByYear(Electricity, year)
    );

    results[`total_fuel_${year}`] = `${FuelForYear.toFixed()} GJ`;
    results[`total_electricity_${year}`] = `${ElectricityForYear.toFixed()} GJ`;
    results[`scope1_${year}`] = `${FuelForYearemission.toFixed()}`;
    results[`scope1_unit`] = `Metric
        tonnes of
        CO2
        equivalent`;
    results[`scope2_unit`] = `Metric
        tonnes of
        CO2
        equivalent`;
    results[`scope2_${year}`] = `${ElectricityForYearemission.toFixed()}`;
    results[`total_energy_${year}`] = `${(
      FuelForYear + ElectricityForYear
    ).toFixed()} GJ`;
    results[`scope1_scope2_${year}`] = `${(
      FuelForYear + ElectricityForYear
    ).toFixed()} GJ`;
  });

  if (isValidBreakdown) {
    console.log({results})
    return years.reduce((acc, year) => {
      acc[`${breakdownLower}_total_fuel_${year}`] =
      results[`total_fuel_${year}`];
      acc[`${breakdownLower}_total_electricity_${year}`] =
      results[`total_electricity_${year}`];
      acc[`${breakdownLower}_total_energy_${year}`] =
        results[`total_energy_${year}`];
      return acc;
    }, {});
  } else {
    console.log({results})
    return results;
  }
}
export function calculateTotalEmissions(data) {
  const currentYear = getCurrentFinancialYear();
  function cal(data, year) {
    const result = {};
    for (const category in data) {
      const dataByYear = (data, year, category) =>
        data[category]?.filter((item) => Number(item.year) === year) || [];
      // console.log(dataByYear(data,year,category))
      result[category] = dataByYear(data, year, category)?.reduce(
        (total, record) => total + parseFloat(record.total_co2e_kg),
        0
      );
    }
    return result;
  }
  let resCurrent = cal(data, currentYear);
  let resLast = cal(data, currentYear - 1);
  return { resCurrent, resLast };
}
export function processFinancialYearData(data) {
  const currentYear = getCurrentFinancialYear();
  const startFinancialYear = currentYear - 1;
  const endFinancialYear = currentYear;

  // Filter data for the current financial year
  const filteredData = data.filter((entry) => {
    const entryYear = parseInt(entry.year);
    return entryYear === startFinancialYear || entryYear === endFinancialYear;
  });

  // Organize data by module and submodule
  const organizedData = filteredData.reduce((acc, entry) => {
    const moduleName = entry?.ModuleMaster?.module || "Undefined Module";
    const subModuleName =
      entry?.SubModuleMaster?.sub_module || "Undefined Submodule";

    if (!acc[moduleName]) {
      acc[moduleName] = {};
    }

    if (!acc[moduleName][subModuleName]) {
      acc[moduleName][subModuleName] = [];
    }

    acc[moduleName][subModuleName].push({
      id: entry.id,
      organization: entry?.organization?.name || "Undefined Organization",
      source_type: entry.source_type || "Undefined Source Type",
      month: entry.month,
      quarter: entry.quarter,
      year: entry.year,
      site: entry?.site_master?.site || "Undefined Site",
      usage_in_kwh: entry.usage_in_kwh,
      total_co2e_kg: entry.total_co2e_kg,
      co2e_co2_kg: entry.co2e_co2_kg,
      co2e_ch4_kg: entry.co2e_ch4_kg,
      co2e_n2o_kg: entry.co2e_n2o_kg,
    });

    return acc;
  }, {});

  return organizedData;
}
