import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  postscope3Category5WasteTreatedData,
  scope3Category5UploadEvidence,
  updatescope3Category5Data,
} from "../../../features/scope3/category5/Scope3Category5Thunk";
import { setscope3Category5WasteTreatedForm } from "../../../features/scope3/category5/Scope3Category5Slice";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";
import { calculateDaysBetween, calculateTreatedQuantity, processDate } from "./process";
import FormGroup from "./FormGroup";

const Category5WasteTreatedFormEditAndCopyForm = ({
  closePurchasePopupForm,
  data,
  actionType,
}) => {
  const api = useApi();
  const siteData = useSelector((state) => state.scope3Category5.siteData);
  const currencyOptions = useSelector((state) => state.scope3Category5.currencyData);
  const unitOptions = useSelector((state) => state.scope3Category5?.unitData);
  const [file, setFile] = useState(null);
  
  let formValue = useSelector((state) => state.scope3Category5.scope3Category5WasteTreatedForm);
  const loading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();
  let {scope3Category5WasteProduceForm,treatmentMechanism,wasteMaterial } = useSelector((state) => state.scope3Category5);
const {treated_period_start, treated_period_end}=scope3Category5WasteProduceForm
  const initialFormValue = {
    treated_period_start: "",
    treated_period_end: "",
    treated_site_id: "",
    waste_material: "",
    waste_material_id: "",
    treated_treatment_mechanism: "",
    treatment_mechanism_id: "",
    treated_proportion_of_waste: "",
    treated_quantity: "",
    treated_unit_id: "",
    treated_amount_spent: "",
    treated_currency_id: "",
    treated_evidence: null,
  };

  const [formValues, setFormValues] = useState([initialFormValue]);

  const totalDays = calculateDaysBetween(treated_period_start, treated_period_end);

  const handleFormChange = (e, index) => {
    const { name, value, files } = e.target;
    const updatedFormValues = [...formValues];
    updatedFormValues[index] = {
      ...updatedFormValues[index],
      [name]: files ? files : value,
    };
    if (name === "treated_period_start" || name === "treated_period_end") {
      if (
        updatedFormValues[index].treated_period_start &&
        updatedFormValues[index].treated_period_end
      ) {
        const days = calculateDaysBetween(
          updatedFormValues[index].treated_period_end,
          updatedFormValues[index].treated_period_start
        );
        updatedFormValues[index]["treated_quantity"] = calculateTreatedQuantity(
          days,
          totalDays
        ).toFixed(2);
      }
    }
    setFormValues(updatedFormValues);
    dispatch(setscope3Category5WasteTreatedForm(updatedFormValues));
  };

  const handleAddNew = () => {
    setFormValues([...formValues, initialFormValue]);
  };

  const handleRemove = (index) => {
    if (formValues.length > 1) {
      setFormValues(formValues.filter((_, i) => i !== index));
    }
  };

  const handlePurchaseFormSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.warning("Please upload evidence");
      return;
    }
    const form = document.querySelector("#purchase-scope3Category5-form");

    if (form && form.checkValidity()) {
      switch (actionType) {
        case "edit":
          dispatch(
            scope3Category5UploadEvidence({ files: file, api, type: 2 })
          ).then((res) => {
            if (res?.payload?.msg === "File uploaded.") {
              dispatch(updatescope3Category5Data({ api, id: data.id }));
            }
          });
          break;
        case "copy":
          dispatch(
            scope3Category5UploadEvidence({ files: file, api, type: 2 })
          ).then((res) => {
            if (res?.payload?.msg === "File uploaded.") {
              dispatch(postscope3Category5WasteTreatedData({ api }));
            }
          });
          break;
        default:
          break;
      }
      closePurchasePopupForm();
    } else if (form) {
      form.querySelector('input[type="submit"]').click();
    }
  };

  useEffect(() => {
    if (data) {
      const updatedData = {
        treated_period_start: processDate(data.treated_period_start),
        treated_period_end: processDate(data.treated_period_end),
        treated_site_id: data?.treated_site_id,
        waste_material: data?.waste_material,
        waste_material_id: data?.waste_material_id,
        treated_treatment_mechanism: data?.treated_treatment_mechanism,
        treatment_mechanism_id: data?.treatment_mechanism_id,
        treated_proportion_of_waste: data?.treated_proportion_of_waste,
        treated_quantity: data?.treated_quantity,
        treated_amount_spent: data?.treated_amount_spent,
        treated_unit_id: data?.treated_unit_id,
        treated_currency_id: data?.treated_currency_id,
        treated_evidence: data?.treated_evidence,
      };

      setFormValues([updatedData]);
      dispatch(setscope3Category5WasteTreatedForm([updatedData]));
    }
  }, [data, actionType]);

  return (
    <div className="flex flex-col justify-center items-center w-full rounded-t-md text-xs">
      <form
        style={{
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? "none" : "auto",
        }}
        id="purchase-scope3Category5-form"
        className="flex flex-col w-full py-4"
        onSubmit={handlePurchaseFormSubmit}
      >
        {formValues.map((formValue, index) => (
          <FormGroup
          treatmentMechanismOptions={treatmentMechanism}
          wasteMaterialOptions={wasteMaterial}
            key={index}
            formValue={formValue}
            handleFormChange={handleFormChange}
            handleRemove={handleRemove}
            currencyOptions={currencyOptions}
            unitOptions={unitOptions}
            siteData={siteData}
            index={index}
          />
        ))}
        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
          <button
            type="button"
            onClick={handleAddNew}
            className="py-2 px-6 text-green-600 text-sm rounded-md"
          >
            Add new
          </button>
          <div className="w-1/6 flex flex-col">
            <button
              disabled={loading}
              type="button"
              className="py-2 px-6 text-green-600 text-sm rounded-md"
              onClick={() => closePurchasePopupForm()}
            >
              Cancel
            </button>
          </div>
          <div className="w-1/6 flex flex-col">
            <button
              type="submit"
              disabled={loading}
              className={`py-2 px-6 text-white text-sm ${
                loading ? "bg-green-500 cursor-wait" : "bg-green-600"
              } rounded-md`}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Category5WasteTreatedFormEditAndCopyForm;

// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import {
//   postscope3Category5WasteTreatedData,
//   scope3Category5UploadEvidence,
//   updatescope3Category5Data,
// } from "../../../features/scope3/category5/Scope3Category5Thunk";
// import { setscope3Category5WasteTreatedForm } from "../../../features/scope3/category5/Scope3Category5Slice";
// import { toast } from "react-toastify";
// import useApi from "../../../customHooks/useApi";
// import { processDate } from "./process";

// const Category5WasteTreatedFormEditAndCopyForm = ({
//   closePurchasePopupForm,
//   data,
//   actionType,
// }) => {
//   const api = useApi();
//   const siteData = useSelector((state) => state.scope3Category5.siteData);

//   const currencyOptions = useSelector(
//     (state) => state.scope3Category5.currencyData
//   );

//   const [file, setfile] = useState(null);
//   const [formData, setFormData] = useState({});
//   let formValue = useSelector(
//     (state) => state.scope3Category5.scope3Category5WasteTreatedForm
//   );
//   const loading = useSelector((state) => state.common.isLoading);
//   const dispatch = useDispatch();
//   const {} = useSelector((state) => state.scope3Category5);
//   let { period_start, period_end } = useSelector(
//     (state) => state.scope3Category5.scope3Category5WasteProduceForm
//   );
//   const unitOptions = useSelector((state) => state.scope3Category5?.unitData);
//   const handleFormChange = (e) => {
//     const { name, value, type } = e.target;
//     if (type === "file") {
//       const files = e.target.files;
//       setfile(files);
//     } else {
//       setFormData({ ...formData, [name]: value });
//       dispatch(
//         setscope3Category5WasteTreatedForm({ ...formData, [name]: value })
//       );
//     }
//   };
//   // console.log(data.id)
//   const handlePurchaseFormSubmit = (e) => {
//     e.preventDefault();
//     if (!file) {
//       toast.warning("Please Upload evidence");
//     }
//     const form = document.querySelector("#purchase-scope3Category5-form");

//     if (form.length && form.checkValidity()) {
//       switch (actionType) {
//         case "edit":
//           dispatch(
//             scope3Category5UploadEvidence({ files: file, api, type: 2 })
//           ).then((res) => {
//             if (res?.payload?.msg === "File uploaded.") {
//               dispatch(updatescope3Category5Data({ api, id: data.id }));
//             }
//           });
//           break;
//         case "copy":
//           dispatch(
//             scope3Category5UploadEvidence({ files: file, api, type: 2 })
//           ).then((res) => {
//             if (res?.payload?.msg === "File uploaded.") {
//               dispatch(postscope3Category5WasteTreatedData({ api }));
//             }
//           });
//           break;
//         default:
//           break;
//       }
//       closePurchasePopupForm();
//     } else if (form.length) {
//       form?.querySelector('input[type="submit"]').click();
//     }
//   };

//   useEffect(() => {
//     if (data) {
//       const updatedData = {
//         site: data?.site,
//         currency: currencyOptions?.find(
//           (item) => data?.currency === item.currency
//         )?.id,
//         period_start: processDate(data.period_start),
//         period_end: processDate(data.period_end),
//         waste_material: data.waste_material,
//         treatment_mechanism: data.treatment_mechanism,
//         proportion_of_waste_treated: data.proportion_of_waste_treated,
//         treated_quantity: data.treated_quantity,
//         amount_spent: data.amount_spent,
//         evidence: data?.evidence,
//       };

//       setFormData(updatedData);
//       dispatch(setscope3Category5WasteTreatedForm({ ...updatedData }));
//     }
//   }, [data, actionType]);

//   // console.log(formData)
//   return (
//     <>
//       {/* <div className="mainContainer flex w-full h-screen overflow-hidden justify-center items-center z-[100] fixed bg-black bg-opacity-50 top-0 left-0 bottom-0"> */}
//       <div className="flex flex-col justify-center items-center w-full rounded-t-md text-xs">
//         <form
//           style={{
//             opacity: loading ? 0.5 : 1,
//             pointerEvents: loading ? "none" : "auto",
//           }}
//           id="purchase-Scope3Category5-form"
//           className="flex flex-col w-full py-4"
//           onSubmit={handlePurchaseFormSubmit}
//         >
//           <div className="grid grid-cols-4 gap-4 px-8 mb-4 justify-around">
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="period_start" className="text-xs py-1.5">
//                 Period Start<span className="text-red-500">*</span>
//               </label>
//               <input
//                 max={new Date().toISOString().split("T")[0]}
//                 type="date"
//                 name="period_start"
//                 id="period_start"
//                 value={formValue.period_start || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="period_end" className="text-xs py-1.5">
//                 Period End<span className="text-red-500">*</span>
//               </label>
//               <input
//                 max={new Date().toISOString().split("T")[0]}
//                 type="date"
//                 name="period_end"
//                 id="period_end"
//                 value={formValue.period_end || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="site" className="text-xs py-1.5">
//                 Site<span className="text-red-500">*</span>
//               </label>
//               <select
//                 name="site"
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 required
//                 value={formValue.site || ""}
//               >
//                 <option value="" disabled>
//                   Choose the site
//                 </option>
//                 {siteData &&
//                   siteData.map((site, index) => (
//                     <option key={site.id} value={site.id}>
//                       {site.site}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="waste_material" className="text-xs py-1.5">
//                 Waste Material<span className="text-red-500">*</span>
//               </label>
//               <select
//                 name="waste_material"
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 required
//                 value={formValue.waste_material || ""}
//               >
//                 <option value="" disabled>
//                   Choose the waste material
//                 </option>
//                 <option value="Plastic">Plastic</option>
//                 <option value="eWaste">eWaste</option>
//                 <option value="Construction and Demolition">
//                   Construction and Demolition
//                 </option>
//                 <option value="Battery Waste">Battery Waste</option>
//                 <option value="Radioactive">Radioactive</option>
//                 <option value="Other Hazardous">Other Hazardous</option>
//                 <option value="Other Non Hazardous">Other Non Hazardous</option>
//                 <option value="Chemical">Chemical</option>
//                 <option value="Agricultural">Agricultural</option>
//                 <option value="Apparel/ Clothing">Apparel/ Clothing</option>
//                 <option value="Glass">Glass</option>
//                 <option value="Paper/ cardboard">Paper/ cardboard</option>
//                 <option value="Bio-Medical">Bio-Medical</option>
//               </select>
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="treatment_mechanism" className="text-xs py-1.5">
//                 Treatment Mechanism<span className="text-red-500">*</span>
//               </label>
//               <input
//                 type="text"
//                 name="treatment_mechanism"
//                 value={formValue.treatment_mechanism || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 placeholder="Type the mechanism"
//                 required
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label
//                 htmlFor="proportion_of_waste_treated"
//                 className="text-xs py-1.5"
//               >
//                 Proportion of Waste Treated
//                 <span className="text-red-500">*</span>
//               </label>
//               <input
//                 type="text"
//                 name="proportion_of_waste_treated"
//                 value={formValue.proportion_of_waste_treated || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 placeholder="Type the proportion"
//                 required
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="treated_quantity" className="text-xs py-1.5">
//                 Treated Quantity
//               </label>
//               <input
//                 type="number"
//                 name="treated_quantity"
//                 value={formValue.treated_quantity || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 placeholder="Type the quantity"
//                 min="0"
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="unit" className="text-xs py-1.5">
//                 Unit
//               </label>
//               <select
//                 name="unit"
//                 value={formValue.unit || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//               >
//                 <option value="" disabled>
//                   Choose the unit
//                 </option>
//                 {unitOptions &&
//                   unitOptions.map((unit, index) => (
//                     <option key={unit.id} value={unit.id}>
//                       {unit.unit}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="amount_spent" className="text-xs py-1.5">
//                 Amount Spent<span className="text-red-500">*</span>
//               </label>
//               <input
//                 type="number"
//                 name="amount_spent"
//                 value={formValue.amount_spent || ""}
//                 onChange={handleFormChange}
//                 min="0"
//                 step="0.01"
//                 placeholder="Type the amount"
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 required
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="currency" className="text-xs py-1.5">
//                 Currency<span className="text-red-500">*</span>
//               </label>
//               <select
//                 name="currency"
//                 value={formValue.currency || ""}
//                 onChange={handleFormChange}
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//                 required
//               >
//                 <option value="" disabled>
//                   Choose the currency
//                 </option>
//                 {currencyOptions.map((currency, index) => (
//                   <option key={index} value={currency.id}>
//                     {currency.currency}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="emission_factor" className="text-xs py-1.5">
//                 Emission Factor (kg CO2e of CO2 per unit)
//               </label>
//               <input
//                 type="number"
//                 name="emission_factor"
//                 value={formValue.emission_factor || ""}
//                 onChange={handleFormChange}
//                 step="0.0001"
//                 placeholder="Type the factor"
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//               />
//             </div>
//             <div className="col-span-1 flex flex-col">
//               <label htmlFor="evidence" className="text-xs py-1.5">
//                 Evidence <span className="text-red-500">*</span>
//               </label>
//               <input
//                 disabled={loading}
//                 type="file"
//                 name="evidence"
//                 multiple
//                 accept=".jpg, .jpeg, .png, .pdf, .zip"
//                 onChange={handleFormChange}
//                 required
//                 className="appearance-none block w-full bg-gray-50 text-neutral-700 text-xs border-0 py-1.5 px-4 leading-tight focus:outline-none"
//               />
//             </div>
//           </div>

//           <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
//             <div className="w-1/6 flex flex-col">
//               <button
//                 disabled={loading}
//                 type="button"
//                 className="py-2 px-6 text-green-600 text-sm rounded-md"
//                 onClick={() => closePurchasePopupForm()}
//               >
//                 Cancel
//               </button>
//             </div>
//             <div className="w-1/6 flex flex-col">
//               <button
//                 type="submit"
//                 disabled={loading}
//                 className={`py-2 px-6 text-white text-sm ${
//                   loading ? "bg-green-500 cursor-wait" : "bg-green-600"
//                 } rounded-md`}
//               >
//                 Submit
//               </button>
//             </div>
//           </div>
//         </form>
//       </div>
//       {/* </div> */}
//     </>
//   );
// };

// export default Category5WasteTreatedFormEditAndCopyForm;
