// features/auth/authThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  clearTokens,
  setTokens,
  setleftSideBarScreenShow,
  setmandatoryStepData,
  setmandatoryStepsCompleted,
} from "./authSlice";
import { toast } from "react-toastify";
import { setIsLoading } from "../common/commonSlice";
import axios from "axios";
import {
  USER_MANAGEMENT_URL,
  PASSWORD,
  BASE_URL,
  USERNAME,
} from "../../appconfig";
import { getOrganizationId } from "../../components/common/parseJwt";

export const refreshTokens = createAsyncThunk(
  "auth/refreshTokens",
  async ({ api, refreshTokenValue }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${USER_MANAGEMENT_URL}/user/refresh-token`,
        {
          refreshToken: refreshTokenValue,
        }
      );
      // const response = await api.post("/refresh-token", {

      const { accessToken, refreshToken: newRefreshToken } =
        response?.data?.data;

      // Update tokens in the Redux store
      dispatch(setTokens({ accessToken, refreshToken: newRefreshToken }));
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      return accessToken;
    } catch (error) {
      // Handle token refresh failure
      console.error("Token refresh failed:", error);
      throw error;
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password }, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      // Call the login API endpoint with username and password
      const response = await axios.post(`${USER_MANAGEMENT_URL}/user/login`, {
        username,
        password,
      });

      const { accessToken, refreshToken } = response?.data?.data?.data;
      // Update tokens in the Redux store
      dispatch(setTokens({ accessToken, refreshToken }));
      toast.success("Login Successful");
      // Return accessToken as the fulfilled value
      return response.data;
    } catch (error) {
      // Handle login failure
      toast.error(error?.response?.data?.error ||error?.message
        || "Login Failed");
      console.error("Login failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ username}, { dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      // Call the login API endpoint with username and password
      const response = await axios.post(`${USER_MANAGEMENT_URL}/user/request-password-reset`, {
        username
      });
      if(response.data.success){
        toast.success(response.data.message||"Send request for reset password");
        return response.data;

      }
    } catch (error) {
      // Handle login failure
      toast.error(error?.response?.data?.error ||error?.message
        || " Failed");
      console.error("Login failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async ({ api }, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      // Call the login API endpoint with username and password
      const response = await api.post(`${USER_MANAGEMENT_URL}/user/logout`);

      toast.success("Logout User");
      dispatch(clearTokens());
      return response.data;
    } catch (error) {
      // Handle login failure
      toast.error(error?.response?.data?.error || "Logout Failed");
      console.error("Logout failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (data, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      const response = await axios.post(
        `${USER_MANAGEMENT_URL}/user/create`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${accessToken}`,
            Authorization: `Basic ${btoa(USERNAME + ":" + PASSWORD)}`,
          },
        }
      );
      // console.log(response)
      toast.success(response?.data?.message || "User created successfullly");
      return response.data;
    } catch (error) {
      // Handle login failure
      toast.error(error?.response?.data?.error || "Failed");
      console.error("register failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const createPassword = createAsyncThunk(
  "auth/createPassword",
  async (data, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    console.log(data);
    // const accessToken = getState().auth.accessToken;
    const accessToken = localStorage.getItem("accessToken");
    try {
      // Call the login API endpoint with username and password
      const response = await axios.post(
        `${USER_MANAGEMENT_URL}/user/change-password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response?.data?.data.success) {
        toast.success("Password created successfully");
      }
      return response.data;
    } catch (error) {
      // console.log({data})
      // Handle login failure
      toast.error(error.response.data.error||"Failed")
      console.error(" failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const postCompanyDetails = createAsyncThunk(
  "auth/postCompanyDetails",
  async ({ api, formData, step_id }, { dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    try {
      // Call the login API endpoint with username and password
      const response = await api.put(`/company-profile/${organization_id}`, {
        ...formData,
        step_id,
      });
      if (response?.data?.success) {
        toast("Company details added Successfully");
        dispatch(validateMandotrySteps({ api }));
      }
      return response.data;
    } catch (error) {
      // Handle login failure
      console.error(" failed:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const UploadLogo = createAsyncThunk(
  "auth/UploadLogo",
  async ({ file, api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));

    const organization_id = getOrganizationId();

    try {
      const formData = new FormData();
      formData.append("file", file); // Append the logo file

      const response = await api.post(
        `upload-company-logo/${organization_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Basic ${btoa(USERNAME + ":" + PASSWORD)}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        toast.success("Profile uploaded successfully", {
          theme: "dark",
        });
        return response?.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error uploading Profile", error);
      toast.error("Uploading Profile failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const Uploadprofile = createAsyncThunk(
  "auth/UploadLogo",
  async ({ file, api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));

    const organization_id = getOrganizationId();

    try {
      const formData = new FormData();
      formData.append("file", file); // Append the logo file
      // console.log(formData)
      const response = await api.post(
        `upload-user-profile/${organization_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Basic ${btoa(USERNAME + ":" + PASSWORD)}`,
          },
        }
      );

      // console.log(response)
      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false));

        toast.success("Logo uploaded successfully", {
          theme: "dark",
        });
        return response?.data;
      }
      return response.data;
    } catch (error) {
      console.error("Error uploading Logo", error);
      toast.error("Uploading Logo failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const validateMandotrySteps = createAsyncThunk(
  "auth/validateMandotrySteps",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    let apiUrl = "/validate-mandatory-steps";
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await api.get(`${apiUrl}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200 && response.data.success) {
        dispatch(setmandatoryStepData(response?.data?.data));
        // console.log(response.data)
        
        dispatch(
          setleftSideBarScreenShow(response.data.data.mandatory_steps_completed)
        );
        dispatch(
          setmandatoryStepsCompleted(
            response.data.data.mandatory_steps_completed
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      // toast.error(error?.response?.data?.error||"Failed")
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
