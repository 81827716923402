export const cat5Data = [
  {
    month: "Mar",
    quarter: "2",
    year: 2024,
    site: "Site 4",
    waste_material: "Non Agreegated",
    waste_impact: "Open-loop",
    produced_quantity: 1100,
    produced_unit: "Kilograms",
    treatment_mechanism: "Open-loop",
    treated_quantity: 508.2,
    treated_unit: "Kilograms",
    amount_spent: null,
    currency: null,
    emissions_kg_co2e_of_co2: 0.501,
    emissions_kg_co2e_of_ch4: 0.05,
    emissions_kg_co2e_of_n2o: 0.05,
    emissions_total_kg_co2e: 0.602,
  },
  {
    month: "Apr",
    quarter: "2",
    year: 2024,
    site: "Site 2",
    waste_material: "Aggregates",
    waste_impact: "Landfill",
    produced_quantity: 1694,
    produced_unit: "Kilograms",
    treatment_mechanism: "Landfill",
    treated_quantity: 1185.8,
    treated_unit: "Kilograms",
    amount_spent: null,
    currency: null,
    emissions_kg_co2e_of_co2: 1.463,
    emissions_kg_co2e_of_ch4: 0.12,
    emissions_kg_co2e_of_n2o: 0.12,
    emissions_total_kg_co2e: 1.7,
  },
  {
    month: "May",
    quarter: "2",
    year: 2024,
    site: "Site 1",
    waste_material: "Aggregates",
    waste_impact: "Open-loop",
    produced_quantity: 1694,
    produced_unit: "Kilograms",
    treatment_mechanism: "Open-loop",
    treated_quantity: 508.2,
    treated_unit: "Kilograms",
    amount_spent: null,
    currency: null,
    emissions_kg_co2e_of_co2: 0.501,
    emissions_kg_co2e_of_ch4: 0.05,
    emissions_kg_co2e_of_n2o: 0.05,
    emissions_total_kg_co2e: 0.602,
  }
];

export function getCurrentFinancialYear() {
  var today = new Date();
  var currentMonth = today.getMonth() + 1;

  if (currentMonth <= 3) {
    return today.getFullYear() - 1;
  } else {
    return today.getFullYear();
  }
}
export  const calculateWasteTreatedPercentage = (data) => {
  const treatedPercentageBySite = {};

  data.forEach((entry) => {
    const { site, produced_quantity, treated_quantity } = entry;

    if (produced_quantity > 0) {
      const treatedPercentage =
        (treated_quantity / produced_quantity) * 100;
      treatedPercentageBySite[site] =
        treatedPercentage.toFixed(2); // Round to 2 decimals
    } else {
      treatedPercentageBySite[site] = 0;
    }
  });

  return treatedPercentageBySite;
};

export const processStackedChartData = (data, year, typebreakdown) => {
  let processedData = {};

  const filteredData = data.filter((entry) => entry.year === Number(year));

  filteredData.forEach((entry) => {
    let { site, emissions_total_kg_co2e } = entry;
    emissions_total_kg_co2e = Number(emissions_total_kg_co2e);
    const breakdown = entry[typebreakdown] || "Total";

    if (!processedData[site]) {
      processedData[site] = {
        site: site,
        breakdown: {},
      };
    }

    if (!processedData[site].breakdown[breakdown]) {
      processedData[site].breakdown[breakdown] = {
        value: 0,
        percentage: 0,
      };
    }

    processedData[site].breakdown[breakdown].value += emissions_total_kg_co2e;
  });

  Object.values(processedData).forEach((entry) => {
    let total = 0;

    Object.values(entry.breakdown).forEach((breakdown) => {
      total += breakdown.value;
    });

    Object.keys(entry.breakdown).forEach((breakdown) => {
      const breakdownValue = entry.breakdown[breakdown].value;
      entry.breakdown[breakdown].percentage =
        total !== 0 ? (breakdownValue / total) * 100 : 0;
    });
  });
  // console.log({processedData})
  return processedData;
};
export const generateSingleCandleChartOptions = (chartData, currentFinancialYear) => {
  // Filter data for the current financial year
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  // Calculate total emissions for the current year
  const totalEmissions = currentYearChartData.reduce(
    (sum, item) => sum + Number(item.total_co2e_kg),
    0
  );

  // Calculate % share of emissions for each site
  const seriesData = currentYearChartData.map((item) => ({
    name: item.site,
    emission:item.total_co2e_kg,
    value: ((item.total_co2e_kg / totalEmissions) * 100).toFixed(), // Calculate percentage
    wasteProduced: item.produced_quantity.toFixed(), // Waste produced in tons
  }));
  const series = seriesData.map(item => ({
    name: item.name,
    type: 'bar',
    stack: 'total',
     label: { 
      show: true, 
      formatter: '{c}%' // Show percentage inside the bar
    },
    data: [item.value]     // Only one value per category (e.g., Sunday)
  }));
  // Chart Options
  return {
    title: { text: "Emission % Share by Site", left: "center" },
    barWidth: 60,
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        const siteData = seriesData.find((s) => s.name === params.seriesName
      );
        if (siteData) {
          return `
            <b>${params.seriesName}</b><br/>
            Emission: ${siteData.emission} Co2<br/>
            Waste Produced: ${siteData.wasteProduced} tons
          `;
        }
        return `Data not found for ${params.name}`;
      },
    },
    legend: {
      // data: seriesData.map((s) => s.name), // Legend based on site names
      top: "bottom",
    },
    grid: {
      left: '3%',
      right: '14%',
      bottom: '30%',
      containLabel: true
    },
    xAxis: {
      type: "value",
     name: "Emission Share (%)",
    },
    yAxis: {
      type: "category",
      data: ["Sites"], // Single bar with no y-axis label
    },
    series:series
  };
};



export const generateSeriesDataForStackedChart = (
  processedData,
  wasteTreatedPercentageData
) => {
  const xAxisData = Object.keys(processedData);
  const legends = new Set();

  Object.values(processedData).forEach((entry) => {
    Object.keys(entry?.breakdown).forEach((breakdown) => {
      legends.add(breakdown);
    });
  });

  const series = [...legends].map((legend) => ({
    name: legend,
    type: "bar",
    stack: "total",
    label: {
      show: true,
      formatter: (params) => {
        const site = params.name;
        const entry = processedData[site]?.breakdown[params.seriesName];
        const percentage = entry?.percentage;
        if (
          percentage !== undefined &&
          !isNaN(percentage) &&
          percentage !== 0
        ) {
          return `${percentage.toFixed()}%`;
        } else {
          return "";
        }
      },
    },
    data: xAxisData.map(
      (site) => processedData[site]?.breakdown[legend]?.value || 0
    ),
  }));

  if (wasteTreatedPercentageData) {
    series.push({
      name: "Waste Treated %",
      type: "line",
      label: { show: true, formatter: `{c}%` },
      data: xAxisData.map((site) => wasteTreatedPercentageData[site] || 0),
    });
  }

  return { series, legends: Array.from(legends) };
};

// const calculateRenewableEnergyData = (data, year) => {
//   // Filter data for renewable and non-renewable energy
//   const filteredDataRenewable = data.filter(
//     (entry) =>
//       entry.year === year && entry.source_type?.toLowerCase() === "renewable"
//   );
//   const filteredDataNonRenewable = data.filter(
//     (entry) =>
//       entry.year === year &&
//       (entry.source_type?.toLowerCase() === "non-renewable" ||
//         entry.source_type?.toLowerCase() === "non renewable")
//   );

//   // Calculate total renewable energy for each site
//   const totalRenewableEnergyBySite = {};
//   filteredDataRenewable.forEach((entry) => {
//     const { site, usage_in_kwh } = entry;
//     totalRenewableEnergyBySite[site] =
//       (totalRenewableEnergyBySite[site] || 0) + parseFloat(usage_in_kwh);
//   });

//   // Calculate total non-renewable energy for each site
//   const totalNonRenewableEnergyBySite = {};
//   filteredDataNonRenewable.forEach((entry) => {
//     const { site, usage_in_kwh } = entry;
//     totalNonRenewableEnergyBySite[site] =
//       (totalNonRenewableEnergyBySite[site] || 0) + parseFloat(usage_in_kwh);
//   });

//   // Calculate total energy for each site
//   const totalEnergyBySite = {};
//   Object.keys(totalRenewableEnergyBySite).forEach((site) => {
//     totalEnergyBySite[site] =
//       (totalRenewableEnergyBySite[site] || 0) +
//       (totalNonRenewableEnergyBySite[site] || 0);
//   });

//   // Calculate renewable energy percentage for each site
//   const renewableEnergyPercentageBySite = {};
//   Object.keys(totalRenewableEnergyBySite).forEach((site) => {
//     const totalRenewableEnergy = totalRenewableEnergyBySite[site] || 0;
//     const totalEnergy = totalEnergyBySite[site] || 0;
//     // Calculate percentage, handle Infinity case
//     renewableEnergyPercentageBySite[site] = isFinite(
//       totalRenewableEnergy / totalEnergy
//     )
//       ? ((totalRenewableEnergy / totalEnergy) * 100).toFixed()
//       : 0;
//   });

//   return renewableEnergyPercentageBySite;
// };

// const processStackedChartData = (data, year, typebreakdown) => {
//   let processedData = {};

//   const filteredData = data.filter((entry) => entry.year === Number(year));

//   filteredData.forEach((entry) => {
//     let { site, total_co2e_kg } = entry;
//     total_co2e_kg = Number(total_co2e_kg);
//     const breakdown = entry[typebreakdown] || "Total";

//     if (!processedData[site]) {
//       processedData[site] = {
//         site: site,
//         breakdown: {},
//       };
//     }

//     if (!processedData[site].breakdown[breakdown]) {
//       processedData[site].breakdown[breakdown] = {
//         value: 0,
//         percentage: 0,
//       };
//     }

//     processedData[site].breakdown[breakdown].value += total_co2e_kg;
//   });

//   Object.values(processedData).forEach((entry) => {
//     let total = 0;

//     Object.values(entry.breakdown).forEach((breakdown) => {
//       total += breakdown.value;
//     });

//     Object.keys(entry.breakdown).forEach((breakdown) => {
//       const breakdownValue = entry.breakdown[breakdown].value;
//       entry.breakdown[breakdown].percentage =
//         total !== 0 ? (breakdownValue / total) * 100 : 0;
//     });
//   });
//   return processedData;
// };
// export const generateSeriesDataForStackedChart = (
//   processedData,
//   renewableEnergyData
// ) => {
//   // console.log(renewableEnergyData)
//   const xAxisData = Object.keys(processedData);
//   const legends = new Set();
//   Object.values(processedData).forEach((entry) => {
//     Object.keys(entry?.breakdown).forEach((breakdown) => {
//       legends.add(breakdown);
//     });
//   });

//   const series = [...legends].map((legend) => ({
//     name: legend,
//     type: "bar",
//     stack: "total",
//     label: {
//       show: true,
//       formatter: (params) => {
//         const site = params.name;
//         const entry = processedData[site]?.breakdown[params.seriesName];
//         const percentage = entry?.percentage;
//         if (
//           percentage !== undefined &&
//           !isNaN(percentage) &&
//           percentage !== 0
//         ) {
//           return `${percentage.toFixed()}%`;
//         } else {
//           return "";
//         }
//       },
//     },
//     data: xAxisData.map(
//       (site) => processedData[site]?.breakdown[legend]?.value || 0
//     ),
//   }));
//   if (renewableEnergyData) {
//     series.push({
//       name: "Renewable Energy Usage",
//       type: "line",
//       label: { show: true, formatter: `{c}%` },

//       data: xAxisData.map((site) => renewableEnergyData[site] || 0),
//     });
//   }

//   // console.log(series)
//   return { series, legends };
// };
export const formatText = (text) => {
  if (text === "module") {
    text = "Scope";
  } else if (text === "sub_module") {
    text = "Category";
  }
  const words = text.split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const formattedText = capitalizedWords.join(" ");

  return formattedText;
};

// current year and last year total emission
function currentYearLastYearEmissionDetail(data, currentYear) {
  if (!currentYear) {
    return {
      currentYear: 0,
      previousYear: 0,
      percentageChange: 0,
    };
  }
  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );
  const totalEmissionsCurrentYear = currentYearData?.reduce(
    (total, entry) => total + Number(entry.total_co2e_kg),
    0
  );
  const totalEmissionsPreviousYear = previousYearData?.reduce(
    (total, entry) => total + Number(entry.total_co2e_kg),
    0
  );

  const percentageChange =
    ((totalEmissionsCurrentYear - totalEmissionsPreviousYear) /
      totalEmissionsPreviousYear) *
    100;

  const roundedPercentageChange = Math.floor(percentageChange);

  return {
    currentYear: totalEmissionsCurrentYear.toFixed(),
    previousYear: Math.floor(totalEmissionsPreviousYear),
    percentageChange: roundedPercentageChange,
  };
}
function calculateEnergyUsageChange(data, currentYear = 2023) {
  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );

  const totalEnergyUsageCurrentYear = currentYearData?.reduce(
    (total, entry) => total + entry.usage_in_kwh,
    0
  );
  const totalEnergyUsagePreviousYear = previousYearData?.reduce(
    (total, entry) => total + entry.usage_in_kwh,
    0
  );

  let percentageChange;
  if (totalEnergyUsagePreviousYear !== 0) {
    percentageChange =
      ((totalEnergyUsageCurrentYear - totalEnergyUsagePreviousYear) /
        totalEnergyUsagePreviousYear) *
      100;
  } else {
    percentageChange = totalEnergyUsageCurrentYear !== 0 ? 100 : 0; // If both years are zero, set percentageChange to 0
  }

  return {
    currentYear: Math.floor(totalEnergyUsageCurrentYear),
    previousYear: Math.floor(totalEnergyUsagePreviousYear),
    percentageChange: Math.floor(percentageChange),
  };
}

// Calculate percentage of renewable energy usage for the current year and the previous year
function calculateRenewableEnergyUsageChange(data, currentYear) {
  const currentYearData = data?.filter((entry) => entry.year === currentYear);
  const previousYearData = data?.filter(
    (entry) => entry.year === currentYear - 1
  );

  const totalUsageCurrentYear = currentYearData?.reduce(
    (total, entry) => total + entry.usage_in_kwh,
    0
  );
  const totalUsagePreviousYear = previousYearData?.reduce(
    (total, entry) => total + entry.usage_in_kwh,
    0
  );

  const renewableUsageCurrentYear = currentYearData?.reduce((total, entry) => {
    if (entry.source_type?.toLowerCase() === "renewable") {
      return total + entry.usage_in_kwh;
    }
    return total;
  }, 0);

  const renewableUsagePreviousYear = previousYearData?.reduce(
    (total, entry) => {
      if (entry.source_type?.toLowerCase() === "renewable") {
        return total + entry.usage_in_kwh;
      }
      return total;
    },
    0
  );
  const percentageRenewableCurrentYear =
    (renewableUsageCurrentYear / totalUsageCurrentYear) * 100 || 0;
  const percentageRenewablePreviousYear =
    (renewableUsagePreviousYear / totalUsagePreviousYear) * 100 || 0;

  const percentageChange =
    ((percentageRenewableCurrentYear - percentageRenewablePreviousYear) /
      percentageRenewablePreviousYear) *
      100 || 0;

  return {
    currentYearPercentage: Math.floor(percentageRenewableCurrentYear),
    previousYearPercentage: Math.floor(percentageRenewablePreviousYear),
    percentageChange: Math.floor(percentageChange),
  };
}

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", {
    month: "short",
  });
}

function processData(Inputdata, timeInterval, breakpoint, year) {
  // console.log(Inputdata)
  // Create a deep copy of Inputdata to avoid modifying the original data
  let dataCopy = JSON.parse(JSON.stringify(Inputdata));
  let data;

  if (year) {
    data = dataCopy.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval == "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    } else if (timeInterval == "Month") {
      function sortByYearAndMonth(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month);
        }
      }

      // Adjust the year for entries with quarter 4
      dataCopy.forEach((item) => {
        if (item.quarter == 4) {
          item.year += 1;
        }
      });

      dataCopy.sort(sortByYearAndMonth);
      data = dataCopy;
    } else if (timeInterval == "Year") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return b.year - a.year;
        }
      }

      dataCopy.sort(sortByYearAndQuarter);
      data = dataCopy;
    }
  }

  let processedData = {};
  data.forEach((entry) => {
    let { year, quarter, total_co2e_kg, month, consumed_fuel } = entry;
    total_co2e_kg = Number(total_co2e_kg);
    consumed_fuel = Number(consumed_fuel);

    let filter = entry[breakpoint] || "Total";

    switch (timeInterval) {
      case "Year":
        if (!processedData[year]) {
          processedData[year] = {};
        }
        if (!processedData[year][filter]) {
          processedData[year][filter] = {
            emissionFactors: [],
            usage_in_kwh_data: 0,
            totalEmissionFactor: "",
            totalEmission: 0,
            consumed_fuel: 0,
          };
        }
        processedData[year][filter].emissionFactors.push(total_co2e_kg);
        processedData[year][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );
        processedData[year][filter].totalEmission += total_co2e_kg;
        processedData[year][filter].consumed_fuel += consumed_fuel;
        break;

      case "Quarter":
        const quarterKey = `${year}-Q${quarter}`;
        if (!processedData[quarterKey]) {
          processedData[quarterKey] = {};
        }
        if (!processedData[quarterKey][filter]) {
          processedData[quarterKey][filter] = {
            emissionFactors: [],
            totalEmissionFactor: "",
            usage_in_kwh_data: 0,
            totalEmission: 0,
            consumed_fuel: 0,
          };
        }
        processedData[quarterKey][filter].emissionFactors.push(total_co2e_kg);
        processedData[quarterKey][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );
        processedData[quarterKey][filter].totalEmission += total_co2e_kg;
        processedData[quarterKey][filter].consumed_fuel += consumed_fuel;
        break;

      case "Month":
        const monthKey = `${year}-${month}`;
        if (!processedData[monthKey]) {
          processedData[monthKey] = {};
        }
        if (!processedData[monthKey][filter]) {
          processedData[monthKey][filter] = {
            emissionFactors: [],
            totalEmissionFactor: "",
            usage_in_kwh_data: 0,
            totalEmission: 0,
            consumed_fuel: 0,
          };
        }
        processedData[monthKey][filter].emissionFactors.push(total_co2e_kg);
        processedData[monthKey][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );
        processedData[monthKey][filter].totalEmission += total_co2e_kg;
        processedData[monthKey][filter].consumed_fuel += consumed_fuel;
        break;

      default:
        break;
    }
  });

  for (const timeKey in processedData) {
    for (const key in processedData[timeKey]) {
      const factors = processedData[timeKey][key].emissionFactors;
      let sum = factors.reduce((acc, curr) => acc + curr, 0);

      processedData[timeKey][key].totalEmissionFactor = sum.toFixed();
    }
  }

  return processedData;
}

function processDataToLablesAndDataSets(data) {
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);

  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }

  for (const legend of legends) {
    let seriesData = [];
    let usage_in_kwh_data = [];
    let consumed_fuel_data = [];

    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push(data[timeKey][legend].totalEmission.toFixed());
        usage_in_kwh_data.push(data[timeKey][legend]?.usage_in_kwh_data);
        consumed_fuel_data.push(data[timeKey][legend]?.consumed_fuel || 0); // Include consumed fuel
      } else {
        seriesData.push(0); // Placeholder for missing data
        consumed_fuel_data.push(0); // Placeholder for missing consumed fuel data
      }
    }
    let totalEmissionsByTimeKey = xAxisLabels.reduce((acc, timeKey) => {
      acc[timeKey] = Object.values(data[timeKey]).reduce((total, siteData) => {
        return total + (siteData.totalEmission || 0);
      }, 0);
      return acc;
    }, {});
    series.push({
      usage_in_kwh_data,
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: true,
        position: legend.trim() === "Total" ? "top" : "", // Different label positions
        formatter: (params) => {
          const timeKeyIndex = params.dataIndex; // Current timeKey index
          const timeKey = xAxisLabels[timeKeyIndex]; // Get the current timeKey
          const totalEmissionForTimeKey = totalEmissionsByTimeKey[timeKey]; // Total emission across all sites for the current timeKey

          const value = Number(params.value); // Current block value

          // Calculate the percentage of the current block relative to the total emission for the timeKey
          let percentage = ((value / totalEmissionForTimeKey) * 100).toFixed();

          // Handle edge cases for NaN, undefined, or 0
          if (isNaN(percentage) || !isFinite(percentage)) {
            percentage = 0;
          }

          // Show value or percentage based on legend name
          return legend.trim() === "Total"
            ? `${value > 10 ? value : ""}`
            : `${percentage > 5 ? percentage + "%" : ""}`;
        },
        fontSize: 10,
      },
      data: seriesData.map((value, index) => ({
        value,
        consumed_fuel: consumed_fuel_data[index], // Attach consumed fuel to each data point
      })),
    });
  }

  return { xAxisLabels, series };
}

const generateChartOption = (titleText, legendData, xAxisLabels, series) => {
  return {
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],

    title: {
      text: titleText,
      left: "center",
    },
    // label:{
    // show:true
    // },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    responsive: false,
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { seriesName, value, data } = params;

        // Initialize the tooltip with emission information
        let tooltipText = `${seriesName} <br/> Emission: ${value} kg Co2e`;

        // Add consumed fuel data if available and relevant to the series
        // if (data && data.consumed_fuel) {
        //   tooltipText += `<br/> Consumed Fuel: ${data.consumed_fuel.toFixed()} Liters`;
        // }

        return tooltipText;
      },
    },
    legend: {
      top: "bottom",
      padding: [30, 10, 10, 10], // top, right, bottom, left
      itemGap: 10,
    },
    labelLine: {
      show: true,
    },
    grid: {
      left: 100,
      right: 100,
      top: 50,
      bottom: series?.length > 6 ? series.length * 10 - 10 : 60, // Adjust bottom space based on series length
      containLabel: false,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000000) {
            return (value / 1000000).toFixed() + "M Kg Co2e";
          } else if (value >= 1000) {
            return (value / 1000).toFixed() + "K Kg Co2e";
          } else {
            return (
              value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " Kg Co2e"
            );
          }
        },
      },
    },
    xAxis: {
      type: "category",
      data: xAxisLabels,
    },
    series: series.map((item) => ({
      ...item,
      data: item.data.map((dataPoint) => ({
        ...dataPoint,
        consumed_fuel: dataPoint.consumed_fuel || 0, // Ensure consumed_fuel is included
      })),
    })),
  };
};

function processDataForSourceType(Inputdata, timeInterval, breakpoint, year) {
  let data;

  if (year) {
    data = Inputdata.filter((entry) => entry.year === year);
    data.sort((a, b) => a.quarter - b.quarter);
  } else {
    if (timeInterval == "Quarter") {
      function sortByYearAndQuarter(a, b) {
        if (a.year !== b.year) {
          return a.year - b.year;
        } else {
          return a.quarter - b.quarter;
        }
      }

      Inputdata.sort(sortByYearAndQuarter);
      data = Inputdata;
    } else {
      function sortByYearAndMonth(a, b) {
        if (a?.year !== b?.year) {
          return a.year - b.year;
        } else {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthsOrder?.indexOf(a?.month) - monthsOrder.indexOf(b?.month);
        }
      }

      Inputdata.sort(sortByYearAndMonth);
      data = Inputdata;
      // console.log(data)
    }
  }

  let processedData = {};

  data.forEach((entry) => {
    const { year, quarter, month } = entry;
    let filter = entry[breakpoint] || "total";
    switch (timeInterval) {
      case "Year":
        if (!processedData[year]) {
          processedData[year] = {};
        }
        if (!processedData[year][filter]) {
          processedData[year][filter] = {
            usage_in_kwh_data: 0,
          };
        }

        processedData[year][filter].usage_in_kwh_data += Number(
          entry["usage_in_kwh"]
        );

        break;

      case "Quarter":
        const quarterKey = `${year}-Q${quarter}`;
        if (!processedData[quarterKey]) {
          processedData[quarterKey] = {};
        }
        if (!processedData[quarterKey][filter]) {
          processedData[quarterKey][filter] = {
            usage_in_kwh_data: 0,
          };
        }
      case "Month":
        const monthKey = `${year}-${month}`;
        if (!processedData[monthKey]) {
          processedData[monthKey] = {};
        }
        if (!processedData[monthKey][filter]) {
          processedData[monthKey][filter] = {
            usage_in_kwh_data: 0,
          };
        }

        processedData[monthKey][filter].usage_in_kwh_data +=
          entry["usage_in_kwh"];

        break;

      default:
        break;
    }
  });

  return processedData;
}

function processSourceTypeDataToLablesAndDataSets(data) {
  let series = [];
  let legends = [];
  let xAxisLabels = Object.keys(data);

  for (const timeKey in data) {
    for (const key in data[timeKey]) {
      if (!legends.includes(key)) {
        legends.push(key);
      }
    }
  }

  for (const legend of legends) {
    let seriesData = [];
    let usage_in_kwh_data = [];

    for (const timeKey of xAxisLabels) {
      if (data[timeKey][legend]) {
        seriesData.push({ value: data[timeKey][legend]?.usage_in_kwh_data });
        usage_in_kwh_data.push(data[timeKey][legend]?.usage_in_kwh_data);
      } else {
        seriesData.push({ value: 0 }); // Placeholder for missing data
      }
    }

    series.push({
      usage_in_kwh_data,
      name: legend,
      type: "bar",
      stack: "total",
      barWidth: "20%",
      label: {
        show: false,
      },

      data: seriesData,
    });
  }

  return { xAxisLabels, series };
}



const generatePieChartOptions = (
  text,
  filteredData,
  breaktype,
  currentFinancialYear
) => {
  const { series } =
    breaktype !== "source_type"
      ? processDataToLablesAndDataSets(
          processData(
            filteredData,
            "Year",
            breaktype,
            Number(currentFinancialYear)
          )
        )
      : processSourceTypeDataToLablesAndDataSets(
          processData(
            filteredData,
            "Year",
            breaktype,
            Number(currentFinancialYear)
          )
        );
  // console.log({series,breaktype})
  const pieChartData =
    series &&
    series.map((dataItem) => ({
      value: dataItem?.data
        ?.reduce((acc, curr) => acc + Number(curr?.value), 0)
        .toFixed(),
      name: dataItem.name,
    }));

  return {
    responsive: true,
    color: [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
      "#D14A61",
      "#675BBA",
      "#F5994E",
      "#A0A7E6",
      "#7F9EC6",
      "#91C7AE",
      "#D48265",
      "#61A0A8",
      "#C4CCD3",
      "#D53A35",
      "#4E91D5",
      "#6B4A3E",
      "#CA8622",
      "#BDA29A",
      "#CC3C5D",
      "#00A29B",
      "#FF7F50",
      "#6495ED",
      "#FFD700",
      "#32CD32",
      "#FFA07A",
      "#20B2AA",
      "#87CEEB",
      "#778899",
      "#B0E0E6",
      "#FF6347",
      "#4682B4",
      "#8B0000",
      "#008080",
      "#FFD700",
      "#4B0082",
      "#DC143C",
      "#2E8B57",
      "#FF69B4",
      "#FF4500",
      "#808000",
      "#FF1493",
      "#00CED1",
      "#1E90FF",
      "#8A2BE2",
      "#A52A2A",
      "#5F9EA0",
      "#FF8C00",
      "#B22222",
      "#DAA520",
      "#228B22",
      "#FFDAB9",
      "#FF00FF",
      "#7FFF00",
      "#FA8072",
      "#F08080",
      "#FFB6C1",
      "#E0FFFF",
      "#FFE4E1",
      "#7B68EE",
      "#D2691E",
      "#9ACD32",
      "#B8860B",
      "#9932CC",
      "#00FF7F",
      "#40E0D0",
      "#FFFAF0",
      "#8FBC8F",
      "#BDB76B",
      "#FFDEAD",
      "#7CFC00",
      "#F0E68C",
      "#E6E6FA",
      "#FFF0F5",
      "#00FF00",
      "#F4A460",
      "#D8BFD8",
      "#FF7F50",
      "#FF6347",
      "#FFDAB9",
      "#FFE4B5",
      "#FFFACD",
      "#00FA9A",
      "#DDA0DD",
      "#FFF5EE",
      "#B0C4DE",
      "#B0E0E6",
      "#87CEEB",
      "#00BFFF",
      "#FFD700",
      "#32CD32",
      "#FFA500",
      "#CD5C5C",
      "#D3D3D3",
      "#4682B4",
      "#FF00FF",
      "#00CED1",
      "#808080",
    ],
    title: {
      text,
      left: "center",
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const entry = series.find((item) => params.name === item.name);
        let tooltipText = "";

        if (entry) {
          const usageInKwh = entry?.usage_in_kwh_data
            ? parseFloat(entry.usage_in_kwh_data)
            : 0;
          const emissionIntensity =
            usageInKwh !== 0 ? (params.value / usageInKwh).toFixed(4) : 0;
          const validEmissionIntensity =
            isFinite(emissionIntensity) && !isNaN(emissionIntensity)
              ? emissionIntensity
              : 0;

          switch (breaktype) {
            case "electricity_source":
            case "transaction_type":
            case "site":
            case "fuel_type":
              tooltipText += `${params.name}<br/>Emissions: ${parseFloat(
                params.value
              ).toFixed()} Kg Co2e <br/>Energy Usage: ${usageInKwh.toFixed()} kwh<br/>Emission Intensity: ${validEmissionIntensity}`;
              break;
            case "source_type":
              tooltipText += `${
                params.name
              }<br/>Energy Usage: ${usageInKwh.toFixed()} kwh`;
              break;
            default:
              tooltipText += `${params.name}<br/>Emissions: ${parseFloat(
                params.value
              ).toFixed()} Kg Co2e<br/>Energy Usage: ${usageInKwh.toFixed()} kwh `;
              break;
          }
        } else {
          tooltipText += `${params.name}<br/>Emissions: ${parseFloat(
            params.value
          ).toFixed()} Kg Co2e`;
        }

        return tooltipText;
      },
    },
    legend: {
      // type: 'scroll', // Enable scrollable legend
      // orient: 'horizontal', // Orient legend vertically
      // right: 10,
      // top: 20,
      // bottom: 20,
      // type: 'scroll', // Enable scrollable legend
      orient: "horizontal", // Orient legend vertically
      bottom: "5%",
      padding: [0, 0, 0, 0],
      top: "bottom",
      formatter: (name) => {
        // Shorten the legend name if it's too long
        // return name.length > 15 ? `${name.slice(0, 15)}...` : name;
        return name;
      },
      pageIconSize: [12, 12], // Set the size of the page icons
      pageTextStyle: {
        fontSize: 12,
      },
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "50%"], // Center the pie chart
        avoidLabelOverlap: false,
        label: {
          position: "inside",
          show: true,
          fontSize: 10,
          fontWeight: "bold",
          formatter: (params) => {
            return `${params?.percent?.toFixed()}%`;
          },
        },
        labelLine: {
          show: true,
        },
        data: pieChartData,
      },
    ],
  };
};

const generateStackedChartOptions = (
  chartData,
  currentFinancialYear,
  typeBreakdown,
  legends,
  xAxisData,
  series
) => {
  const currentYearChartData = chartData.filter(
    (item) => item.year === Number(currentFinancialYear)
  );

  if (!currentYearChartData || !currentYearChartData.length) {
    console.warn("No data available for the current financial year.");
    return {};
  }

  const yAxis = [
    {
      type: "value",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000000) {
            return (value / 1000000).toFixed() + "M Kg CO2e";
          } else if (value >= 1000) {
            return (value / 1000).toFixed() + "K Kg CO2e";
          } else {
            return (
              value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " Kg CO2e"
            );
          }
        },
      },
    },
    {
      type: "value",
      max: 100,
      axisLabel: {
        formatter: "{value}%",
      },
      splitLine: {
        show: false,
      },
    },
  ];

  return {
    title: { text: "Facilitywise Emissions and Waste Breakdown", left: "center" },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        let tooltipContent = `${params.seriesName}<br/>`;

        // Bar chart (emissions breakdown)
        if (params.seriesType === "bar") {
          tooltipContent += `Emission: ${params.value.toFixed()} Kg CO2e<br/>`;

          const entry = currentYearChartData?.find(
            (item) => item[typeBreakdown] === params.seriesName && item.site === params.name
          );
          if (entry) {
            const wasteProduced = entry["produced_quantity"];
            tooltipContent += `Waste Produced: ${wasteProduced.toFixed()} tons<br/>`;
          } else {
            console.warn(`No data found for ${params.seriesName} at site ${params.name}.`);
          }
        }
        // Line chart (waste treated percentage)
        else if (params.seriesType === "line" && params.seriesName === "Waste Treated %") {
          const entry = currentYearChartData.find((item) => item.site === params.name);
          console.log(entry)
          if (entry) {
            // tooltipContent += `Waste Treated: ${params?.value?.toFixed(2)}%<br/>`;
            tooltipContent += `Treated Quantity: ${entry["treated_quantity"]?.toFixed()} tons<br/>`;
          } else {
            console.warn(`No data found for waste treated percentage at site ${params.name}.`);
          }
        }

        return tooltipContent;
      },
    },
    legend: {
      type: "scroll",
      data: [...legends, "Waste Treated %"], // Include legend for line series
      top: "bottom",
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    yAxis: yAxis,
    series: series.map((s) => {
      if (s.type === "line") {
        return {
          ...s,
          yAxisIndex: 1, // Assign to the secondary y-axis for percentage
          name: "Waste Treated %", // Assign the correct name for the legend
        };
      }
      return s;
    }),
  };
};

// const generateStackedChartOptions = (
//   chartData,
//   currentFinancialYear,
//   typeBreakdown,
//   legends,
//   xAxisData,
//   series
// ) => {
//   const currentYearChartData = chartData.filter(
//     (item) => item.year === Number(currentFinancialYear)
//   );

//   if (!currentYearChartData || !currentYearChartData.length) {
//     console.warn("No data available for the current financial year.");
//     return {};
//   }


//   const yAxis = [
//     {
//       type: "value",
//       axisLabel: {
//         formatter: (value) => {
//           if (value >= 1000000) {
//             return (value / 1000000).toFixed() + "M Kg Co2e";
//           } else if (value >= 1000) {
//             return (value / 1000).toFixed() + "K Kg Co2e";
//           } else {
//             return (
//               value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
//               " Kg Co2e"
//             );
//           }
//         },
//       },
//     },
//     {
//       type: "value",
//       max: 100,
//       axisLabel: {
//         formatter: "{value}%",
//       },
//       splitLine: {
//         show: false,
//       },
//     },
//   ];

//   return {
//     title: { text: "Facilitywise Emissions", left: "center" },
//     toolbox: {
//       show: true,
//       orient: "vertical",
//       left: "right",
//       top: "center",
//       feature: {
//         mark: { show: true },
//         dataView: { show: true, readOnly: true },

//         restore: { show: true },
//         saveAsImage: { show: true },
//       },
//     },
//     tooltip: {
//       trigger: "item",
//       formatter: function (params) {
//         let tooltipContent = "";
// console.log(params)
//         if (params.seriesType === "bar") {
//           tooltipContent += `${
//             params.seriesName
//           }<br/>Emission: ${params.value.toFixed()} Kg Co2e<br/>`;

//           if (params.seriesName !== "Renewable Energy Usage") {
//             const entry = currentYearChartData?.filter(
//               (item) => item[typeBreakdown] === params.seriesName
//             );
//             if (entry && entry.length > 0) {
//               let totalUses = entry?.reduce(
//                 (acc, curr) => acc + Number(curr["usage_in_kwh"]),
//                 0
//               );
//               let totalEmission = entry?.reduce(
//                 (acc, curr) => acc + Number(curr["total_co2e_kg"]),
//                 0
//               );
//               if (totalUses) {
//                 tooltipContent += `Energy Usage: ${totalUses.toFixed()} kWh<br/>`;
//                 tooltipContent += `Emission Intensity: ${(
//                   totalEmission / totalUses
//                 ).toFixed(2)}<br/>`;
//               }
//             } else {
//               console.warn(`No data found for ${params.seriesName}.`);
//             }
//           }
//         } else if (
//           params.seriesType === "line" &&
//           params.seriesName === "Renewable Energy Usage"
//         ) {
//           const renewableEntries = currentYearChartData.filter(
//             (item) =>
//               item.site === params.name &&
//               item.source_type?.toLowerCase() === "renewable"
//           );
//           const sumCurrentYearEnergyUses = renewableEntries?.reduce(
//             (sum, entry) => {
//               if (entry.year === currentFinancialYear) {
//                 sum += parseFloat(entry.usage_in_kwh);
//               }
//               return sum;
//             },
//             0
//           );
//           tooltipContent += `${
//             params.name
//           } <br/>Renewable Energy Usage: ${sumCurrentYearEnergyUses.toFixed()}kWh<br/>`;
//         }

//         // // Include renewable energy percentage in the tooltip for bar series
//         // if (params.seriesType === 'bar' && renewableEnergyPercentageBySite[params.name] !== undefined) {
//         //   tooltipContent += `Renewable Energy Percentage: ${renewableEnergyPercentageBySite[params.name].toFixed(2)}%<br/>`;
//         // }

//         return tooltipContent;
//       },
//     },
//     legend: {
//       type: "scroll",
//       data: [...legends, "Renewable Energy Usage"], // Include legend for line series
//       top: "bottom",
//     },
//     xAxis: {
//       type: "category",
//       data: xAxisData,
//     },
//     yAxis: yAxis,

//     series: series.map((s, index) => {
//       if (s.type === "line") {
//         return {
//           ...s,
//           yAxisIndex: 1, // Assigning to the secondary y-axis
//           name: "Renewable Energy Usage", // Assign a name for the legend
//         };
//       }
//       return s;
//     }),
//   };
// };

const generateOptionforSunburst = (InputData, currentFinancialYear) => {
  // console.log(currentFinancialYear,"currentFinancialYear")
  const data = InputData.filter((item) => item.year === currentFinancialYear); /// currentFinancialYear filter
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid or empty data provided");
    return {}; // Return empty option object if data is invalid or empty
  }

  // Calculate total CO2 emissions for each module and overall
  const moduleTotals = {};
  let totalEmissions = 0;
  data.forEach((entry) => {
    const moduleName = entry.module;
    const subModuleName = entry.sub_module;
    const emissions = parseFloat(entry.total_co2e_kg);

    // Calculate total emissions
    totalEmissions += emissions;

    // Calculate emissions for each module and sub-module
    if (!moduleTotals[moduleName]) {
      moduleTotals[moduleName] = { total: 0, subModules: {} };
    }
    moduleTotals[moduleName].total += emissions;

    if (!moduleTotals[moduleName].subModules[subModuleName]) {
      moduleTotals[moduleName].subModules[subModuleName] = 0;
    }
    // console.log(moduleTotals[moduleName].subModules[subModuleName],emissions,subModuleName)
    moduleTotals[moduleName].subModules[subModuleName] += emissions;
  });
  // Transform data for sunburst chart
  const transformDataForSunburst = (data) => {
    const formattedData = [];
    for (const moduleName in moduleTotals) {
      const moduleTotal = moduleTotals[moduleName].total;
      const modulePercentage = isNaN((moduleTotal / totalEmissions) * 100)
        ? 0
        : (moduleTotal / totalEmissions) * 100;
      const moduleData = {
        name: `${moduleName} (${modulePercentage.toFixed() || 0}%)`,
        value: Number(moduleTotal.toFixed()),
        children: [],
      };
      for (const subModuleName in moduleTotals[moduleName].subModules) {
        const subModuleEmissions =
          moduleTotals[moduleName].subModules[subModuleName];
        const percentage = isNaN((subModuleEmissions / moduleTotal) * 100)
          ? 0
          : (subModuleEmissions / moduleTotal) * 100;
        moduleData.children.push({
          name: `${subModuleName} (${percentage.toFixed() || 0}%)`,
          value: Number(subModuleEmissions.toFixed()),
        });
      }
      formattedData.push(moduleData);
    }
    // console.log(formattedData)
    return formattedData;
  };

  // Generate option for the sunburst chart
  const option = {
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        restore: { show: true }, // Ensure that the restore feature is enabled
        saveAsImage: { show: true },
      },
    },
    tooltip: {},
    // visualMap: {
    // type: 'piecewise',
    // min: 0,
    // max: 50000,
    // inRange: {
    //   color: ['#2F93C8', '#AEC48F', '#FFDB5C', '#F98862']
    // }
    // },
    title: {
      text: "Scopewise Emission Contribution",
      left: "center",
      // top:-5
    },

    series: {
      type: "sunburst",
      data: transformDataForSunburst(data),
      radius: [70, "80%"],
      label: {
        show: true,
        formatter: (params) => {
          return `${params.name.slice(0, 14)}`;
        },
        rotate: 45,
        // fontSize: 10,
        fontWeight: 600,
      },
      itemStyle: {
        borderRadius: 5,
        borderWidth: 2,
      },
    },
  };
  // console.log(option)
  return option;
};

export {
  processData,
  processDataForSourceType,
  generateStackedChartOptions,
  processDataToLablesAndDataSets,
  currentYearLastYearEmissionDetail,
  calculateRenewableEnergyUsageChange,
  calculateEnergyUsageChange,
  processSourceTypeDataToLablesAndDataSets,
  
  generatePieChartOptions,
  generateChartOption,
  // calculateRenewableEnergyData,
  generateOptionforSunburst,
};

// fuel cases validation

export function validateFuelData(data) {
  // Validate Case 1: quantity * carbon_content_of_fuel
  // const case1Valid = (data.quantity && data.carbon_content_of_fuel);

  // // Validate Case 2: quantity * heat_content_of_fuel * ef_of_fuel
  // const case2Valid = (data.quantity && data.heat_content_of_fuel && data.ef_of_fuel);

  // // Validate Case 3: quantity * ef_of_fuel
  // const case3Valid = (data.quantity && data.ef_of_fuel);

  // // // Validate Case 4: paid_amount * ef_of_fuel
  // const case4Valid = (data.amount_paid && data.ef_of_fuel);

  // Return true if any of the cases are valid and no extra fields are present
  return true;
}
// Electricity cases validation

export function validateElectricityData(data) {
  // Validate Case 1: quantity * ef_of_fuel
  const case1Valid = data.quantity && data.ef_of_fuel;

  // Return true if any of the cases are valid and no extra fields are present
  return case1Valid;
}

export function removeEmptyProperties(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
}
