import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  body: {
    padding: 10,
    // fontFamily: 'Arial, sans-serif',
  },
  heading: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
    fontSize: 12,
    fontWeight: "bold",
  },
  subheading: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  bold: {
    fontWeight: "bold",
  },
  note: {
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 10,
  },
  subheading: {
    fontSize: 12,
  },
});

export const TempleteDocument = ({ data }) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.heading}>
        PRINCIPLE 6: Businesses should respect and make efforts to protect and
        restore the environment
      </Text>

      <Text style={styles.subheading}>Essential Indicators</Text>
      <Text style={styles.subheading}>
        1. Details of total energy consumption (in Joules or multiples) and
        energy intensity, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total electricity consumption (A)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_electricity_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_electricity_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total fuel consumption (B)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_fuel_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_fuel_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Energy consumption through other sources (C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total energy consumption (A+B+C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_energy_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_energy_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Energy intensity per rupee of turnover{" "}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data?.energy_intensity_perunit_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data?.energy_intensity_perunit_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Energy intensity (optional) – the relevant metric may be selected
              by the entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.energy_intensity_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.energy_intensity_2023}</Text>
          </View>
        </View>
      </View>

      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>

      <Text style={styles.subheading}>
        1. Provide details of the following disclosures related to water, in the
        following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water withdrawal by source (in kilolitres)
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Surface water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Groundwater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iii) Third party water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iv) Seawater / desalinated water
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(v) Others</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total volume of water withdrawal (in kilolitres) (i + ii + iii +
              iv + v)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Total volume of water consumption (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water intensity per rupee of turnover (Water consumed / turnover)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
              Water intensity (optional) – the relevant metric may be selected
              by the entity
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
      </View>

      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>

      <Text style={styles.subheading}>
        1. Please provide details of air emissions (other than GHG emissions) by
        the entity, in the following format::
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Please specify unit</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            NOx
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.nox_2023}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.sox_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>SOx</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.sox_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.sox_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Particulate matter (PM)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Persistent organic pollutants (POP)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Volatile organic compounds (VOC)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Hazardous air pollutants (HAP)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            Others – please specify
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
       
      </View>
      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>

      <Text style={styles.subheading}>
        2. Provide details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) & its intensity, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>unit</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.scope1_unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF 6, NF3, if available)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.scope1_unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope2_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope2_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total Scope 1 and Scope 2 emissions per rupee of turnover</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope_unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_scope1_scope2_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_scope1_scope2_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total Scope 1 and Scope 2 emission intensity (optional) – the relevant metric may be selected by the entity</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.scope1_scope2_intensity_unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.scope1_scope2_intensity_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.scope1_scope2_intensity_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Volatile organic compounds (VOC)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data?.unit}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
    
       
      </View>

      






      <Text style={styles.subheading}>
      8. Provide details related to waste management by the entity, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
              Total waste generated (in metric tonnes)
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Plastic waste (A)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.plastic_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.plastic_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            E-waste (B)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Bio-medical waste (C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Construction and demolition waste (D)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Battery waste (E)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Radioactive waste (F)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.bio_medical_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Other Hazardous waste. Please specify, if any. (G)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.hazardous_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.hazardous_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Other Non-hazardous waste generated (H). Please specify, if any. (Break-up by composition i.e. by materials relevant to the sector)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Total (A+B + C + D + E + F + G + H)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.non_hazardous_waste_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
            For each category of waste generated, total waste recovered through recycling, re-using or other recovery operations (in metric tonnes)
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
            Category of waste
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            (i) Recycled
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            (ii) Re-used
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            (iii) Other recovery operations
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Total
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
            For each category of waste generated, total waste disposed by nature of disposal method (in metric tonnes)
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
            Category of waste
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            (i) Incineration
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            (ii) Landfilling
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            (iii) Other recovery operations
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
            Total
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.e_waste_2023}</Text>
          </View>
        </View>
      </View>





      <Text style={styles.subheading}>Leadership Indicators</Text>
      <Text style={styles.subheading}>
        1.Provide break-up of the total energy consumed (in Joules or multiples) from renewable and non-renewable sources, in the following format:
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
            From renewable sources
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total electricity consumption (A)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.renewable_total_electricity_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.renewable_total_electricity_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total fuel consumption (B)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.renewable_total_fuel_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.renewable_total_fuel_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Energy consumption through other sources (C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            Total energy consumed from renewable sources (A+B+C)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_energy_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.renewable_total_energy_2023}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={styles.tableCell}>
            From non renewable sources
            </Text>
          </View>
          {/* <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.total_waste_2023}</Text>
          </View> */}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Total electricity consumption (D)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data["non-renewable_total_electricity_2024"]}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data["non-renewable_total_electricity_2023"]}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>Total fuel consumption (E)</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data["non-renewable_total_fuel_2024"]}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data["non-renewable_total_fuel_2023"]}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              Energy consumption through other sources (F)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.energy_source_c_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            Total energy consumed from non-renewable sources (D+E+F)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_energy_2024"]}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data["non-renewable_total_energy_2024"]}
            </Text>
          </View>
        </View>
      </View>

      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>
























      <Text style={styles.subheading}>Provide the following details related to water discharged:</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>Parameter</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2024</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>FY 2023</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "100%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            Water discharge by destination and level of treatment (in kilolitres)
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(i) Surface water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- With treatment – please specify level of treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(ii) Groundwater</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.groundwater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- With treatment – please specify level of treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(iii) Third party water</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.third_party_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- With treatment – please specify level of treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>
              (iv) Seawater / desalinated water
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.seawater_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- With treatment – please specify level of treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>(v) Others</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.other_sources_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- No treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={styles.tableCell}>- With treatment – please specify level of treatment</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2024}</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>{data.surface_water_2023}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "50%" }]}>
            <Text style={[styles.tableCell, styles.bold]}>
            Total water discharged (in kilolitres)
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2024}
            </Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>
              {data.total_water_withdrawal_2023}
            </Text>
          </View>
        </View>
        
      </View>

      <Text style={styles.note}>
        Note: Indicate if any independent assessment/evaluation/assurance has
        been carried out by an external agency? (Y/N) If yes, name of the
        external agency.
      </Text>
      
    </Page>
  </Document>
);
