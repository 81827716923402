// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import { ReactComponent as Scope3Icon } from "../../app/assets/Category4.svg";
// import { ReactComponent as CopyIcon } from "../../app/assets/CopyIcon.svg";
// import { ReactComponent as PencilIcon } from "../../app/assets/PencilIcon.svg";
// import { ReactComponent as DeleteIcon } from "../../app/assets/DeleteIcon.svg";
// import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
// import { ReactComponent as UserIcon } from "../../app/assets/UserIcon.svg";
// import { ReactComponent as WasteTreatedIcon } from "../../app/assets/WasteTreatedIcon.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
// import {
//   resetscope3Category5Form,
//   setCurrentPage,
//   setItemsPerPage,
//   setscope3Category5Form,
// } from "../../features/scope3/category5/Scope3Category5Slice";
// import Header from "../../components/common/Header";
// import TableMeta from "../../components/common/Table/TableMeta";
// import Table from "../../components/common/Table/Table";
// import TablePagination from "../../components/common/Table/TablePagination";
// import { deletescope3Category5Data } from "../../features/scope3/category5/Scope3Category5Thunk";
// import { generateLogisticsData } from "./function/UniqueLogisticMode";
// import useApi from "../../customHooks/useApi";
// import { filterSpecificationsByVehicleType } from "./function/filterspecifications";
// import Category5FormPopUp from "../../components/scope3/category5/Category5FormPopUp"; // Import the popup component

// const Scope3Category5 = () => {
//   const api = useApi();
//   const dispatch = useDispatch();
//   const scope3Category5InputRecords = useSelector(
//     (state) => state?.scope3Category5?.scope3Category5InputRecords || []
//   );
//   const [files, setfiles] = useState(null);
//   const itemsPerPage = useSelector(
//     (state) => state?.scope3Category5?.itemsPerPage
//   );
//   const scope3Category5Form = useSelector(
//     (state) => state?.scope3Category5?.scope3Category5Form
//   );
//   const totalPages = useSelector((state) => state?.scope3Category5?.totalPages);
//   const totalCount = useSelector((state) => state?.scope3Category5?.totalCount);
//   const currentPage = useSelector(
//     (state) => state?.scope3Category5?.currentPage
//   );

//   const [dataRows, setDataRows] = useState([]);

//   const [manualEntry, setManualEntry] = useState(true);
//   const [apiIntegration, setApiIntegration] = useState(false);
//   const [trendLine, setTrendLine] = useState(false);
//   const [selectedTab, setSelectedTab] = useState(1);
//   const [selectedWasteType, setSelectedWasteType] = useState(1);
//   const [actionType, setActionType] = useState(null);
//   const [selectedRowData, setSelectedRowData] = useState(null);
//   const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
//   const [isCategory5FormPopupOpen, setIsCategory5FormPopupOpen] =
//     useState(false); // Add state for popup

//   const headingsToDataKeyMap = {
//     " ": "",
//     "PERIOD START": "period_start",
//     "PERIOD END": "period_end",
//     SITE: "site",
//     "WASTE MATERIAL": "waste_material",
//     "PRODUCED MECHANISM": "produced_mechanism",
//     " QUANTITY": "quantity",
//     UNIT: "unit",
//     "AMOUNT SPENT": "amount_spent",
//     CURRENCY: "currency",
//     EDIT: "edit",
//     DELETE: "",
//   };
//   const headingsToDataKeyMapforTreated = {
//     " ": "",
//     "PERIOD START": "period_start",
//     "PERIOD END": "period_end",
//     SITE: "site",
//     "WASTE MATERIAL": "waste_material",
//     "TREATMENT MECHANISM": "treatment_mechanism",
//     "PROPORTION OF WASTE TREATED": "proportion_of_waste_treated",
//     "TREATED QUANTITY": "treated_quantity",
//     UNIT: "unit",
//     "AMOUNT SPENT": "amount_spent",
//     CURRENCY: "currency",
//     EDIT: "edit",
//     DELETE: "",
//   };

//   const handleDelete = (row) => {
//     if (row.id) {
//       // dispatch(deletescope3Category5Data({api,id:row.id}))
//     } else {
//       toast.error("Failed please try again");
//     }
//   };

//   const handleEdit = (row) => {
//     dispatch(resetscope3Category5Form());
//     setActionType("edit");
//     setSelectedRowData(row);
//     setIsCategory5FormPopupOpen(true); // Open the popup
//   };

//   const handleCopy = (row) => {
//     dispatch(resetscope3Category5Form());
//     setActionType("copy");
//     setSelectedRowData(row);
//     setIsCategory5FormPopupOpen(true); // Open the popup
//   };

//   const handleCloseEditCopyForms = () => {
//     setIsEditCopyFormOpen(false);
//   };

//   const switchTab = (tabId) => {
//     switch (tabId) {
//       case 1:
//         setManualEntry(true);
//         setApiIntegration(false);
//         setTrendLine(false);
//         break;
//       case 2:
//         setManualEntry(false);
//         setApiIntegration(true);
//         setTrendLine(false);
//         break;
//       case 3:
//         setManualEntry(false);
//         setApiIntegration(false);
//         setTrendLine(true);
//         break;
//       default:
//         setManualEntry(true);
//         setApiIntegration(false);
//         setTrendLine(false);
//         break;
//     }
//     setSelectedTab(tabId);
//   };

//   const openPopupForm = () => {
//     dispatch(resetscope3Category5Form());
//     setActionType("newEntry"); // Set action type for new entry
//     setIsCategory5FormPopupOpen(true); // Open the popup
//   };

//   const closePurchasePopupForm = () => {
//     setIsCategory5FormPopupOpen(false); // Close the popup
//   };

//   const handlePageChange = (page) => {
//     dispatch(setCurrentPage(page));
//   };

//   const handleItemsPerPage = (itemCount) => {
//     dispatch(setItemsPerPage(itemCount));
//   };

//   const handleFileDownload = (filePath) => {
//     if (filePath) {
//       const link = document.createElement("a");
//       link.href = filePath;
//       link.download = "filename.zip";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } else {
//       toast("No evidence attached");
//     }
//   };

//   const generateCellHtml = (row, k) => {
//     let cellHtml = null;
//     switch (k) {
//       case "Scope3_type":
//         cellHtml = (
//           <span className="w-full flex justify-center">
//             <span
//               className={`px-2 py-1 border border-transparent rounded-md ${
//                 row["source_type"].toString().toLowerCase() ===
//                 "Renewable".toLowerCase()
//                   ? "bg-green-200 text-green-600"
//                   : "bg-red-200 text-red-600"
//               }`}
//             >
//               {row[k]}
//             </span>
//           </span>
//         );
//         break;
//       case "edit":
//         cellHtml = (
//           <span className="w-full flex justify-center">
//             <PencilIcon
//               className="cursor-pointer"
//               onClick={() => handleEdit(row)}
//             />
//           </span>
//         );
//         break;
//       case "status":
//         cellHtml = (
//           <span className="w-full flex justify-center">
//             <span
//               className={`px-2 py-1 border border-transparent rounded-md ${
//                 row[k] === "Accepted"
//                   ? "bg-green-200 text-green-600"
//                   : row[k] === "Submitted"
//                   ? "bg-green-200 text-green-600"
//                   : "bg-red-200 text-red-600"
//               }`}
//             >
//               {row[k]}
//             </span>
//           </span>
//         );
//         break;
//       case "evidence":
//         cellHtml = (
//           <span className="w-full flex justify-center">
//             <DownloadIcon
//               className="cursor-pointer fill-green-500"
//               onClick={() => handleFileDownload(row[k])}
//             />
//           </span>
//         );
//         break;
//       case "submitted_by":
//       case "approved_by":
//         cellHtml = (
//           <span className="w-full flex justify-center">
//             <img
//               src={`${UserIcon}`}
//               className="cursor-pointer w-[32px] h-[32px] border border-transparent rounded-[50%]"
//               alt={`${k}`}
//             />
//           </span>
//         );
//         break;
//       default:
//         cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
//         break;
//     }
//     return cellHtml;
//   };

//   const handleFormChange = async (e) => {
//     const { name, type, value } = e.target;
//     if (type === "file") {
//       const files = e.target.files;
//       if (!files) {
//         toast("Please select a file");
//         return;
//       }
//       setfiles(files);
//     } else {
//       dispatch(
//         setscope3Category5Form({ ...scope3Category5Form, [name]: value })
//       );
//     }
//   };

//   useEffect(() => {
//     let transformedDataRows = scope3Category5InputRecords?.map((row) => {
//       let transformedDataRow = [];
//       transformedDataRow.push(
//         <span className="w-full flex justify-center">
//           <CopyIcon
//             className="cursor-pointer"
//             onClick={() => handleCopy(row)}
//           />
//         </span>
//       );
//       Object.values(headingsToDataKeyMap).forEach((k) => {
//         if (k.toString().trim() !== "") {
//           transformedDataRow.push(generateCellHtml(row, k));
//         }
//       });
//       transformedDataRow.push(
//         <span className="w-full flex justify-center">
//           <DeleteIcon
//             className="cursor-pointer"
//             onClick={() => handleDelete(row)}
//           />
//         </span>
//       );
//       return transformedDataRow;
//     });

//     setDataRows(transformedDataRows);
//   }, [scope3Category5InputRecords]);

//   useEffect(() => {
//     // dispatch(fetchscope3Category5InputData({api}))
//     // dispatch(fetchscope3Category5ItemData({api}));
//     // dispatch(fetchCurrencyData({api}));
//     // dispatch(fetchSiteData({api}));
//     // dispatch(fetchUnitData({api}));
//   }, []);
//   function handleWasteTypeChange(type) {
//     if (type === 1) {
//       setSelectedWasteType(1);
//     } else {
//       setSelectedWasteType(2);
//     }
//   }
//   return (
//     <>
//       <Header
//         PageIcon={Scope3Icon}
//         pageTitle={"Downstream Transportation and Distribution"}
//       />

// <div className="flex flex-col main-container w-full px-10 py-6">
// <div className="flex justify-between items-center text-xs">
//   <div className="flex">
//     <button
//       className={`flex border items-center rounded-md mr-4 cursor-pointer px-2 w-48 h-8 ${
//         1 === 1
//           ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
//           : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
//       }`}
//       onClick={() => handleWasteTypeChange(1)}
//     >
//       <WasteTreatedIcon className="fill-current w-4 h-4" />
//       <span className="px-2">Waste Produce</span>
//     </button>
//     <button
//       className={`flex border items-center rounded-md mr-4 cursor-pointer px-2  w-48 h-8 ${
//         2 === 2
//           ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
//           : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
//       }`}
//       onClick={() => handleWasteTypeChange(2)}
//     >
//       <WasteTreatedIcon className="fill-current w-4 h-4" />
//       <span className="px-2">Waste Treated</span>
//     </button>
//   </div>
//   <div className="flex"></div>
// </div>
//         <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
//           <TableMeta
//             recordType={1}
//             totalCount={totalCount}
//             openPopUpForm={openPopupForm}
//             switchTab={switchTab}
//             selectedTab={selectedTab}
//           />
//           {manualEntry && (
//             <>
//               <Table
//                 headings={Object.keys(headingsToDataKeyMap)}
//                 dataRows={dataRows}
//                 paginationObject={{
//                   paginationEnabled: true,
//                   currentPage: currentPage,
//                   totalPages: totalPages,
//                   itemsPerPage: itemsPerPage,
//                   handleItemsPerPage: handleItemsPerPage,
//                   handlePageChange: handlePageChange,
//                 }}
//               />
//               <div className="flex w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
//                 <AddIconBig onClick={() => openPopupForm(1)} />
//               </div>
//               <TablePagination
//                 paginationObject={{
//                   paginationEnabled: true,
//                   currentPage: currentPage,
//                   totalPages: totalPages,
//                   itemsPerPage: itemsPerPage,
//                   handleItemsPerPage: handleItemsPerPage,
//                   handlePageChange: handlePageChange,
//                 }}
//               />
//             </>
//           )}
//           {apiIntegration && (
//             <>
//               <h1>API Integration tab</h1>
//             </>
//           )}
//           {trendLine && (
//             <>
//               <h1>TrendLine tab</h1>
//             </>
//           )}
//         </div>
//       </div>

//       {isCategory5FormPopupOpen && (
//         <Category5FormPopUp
//           handleFormChange={handleFormChange}
//           file={files}
//           type={actionType}
//           data={selectedRowData}
//           closePopup={closePurchasePopupForm}
//         />
//       )}
//     </>
//   );
// };

// export default Scope3Category5;
// File: Scope3Category5.js

import { ReactComponent as AddIconBig } from "../../app/assets/AddIconBig.svg";
import React, { useEffect, useState } from "react";
import { ReactComponent as Scope3Icon } from "../../app/assets/Category4.svg";
import { ReactComponent as WasteTreatedIcon } from "../../app/assets/WasteTreatedIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  resetscope3Category5Form,
  setCurrentPage,
  setItemsPerPage,
  // setscope3Category5Form,
} from "../../features/scope3/category5/Scope3Category5Slice";
import Header from "../../components/common/Header";
import TableMeta from "../../components/common/Table/TableMeta";
import TablePagination from "../../components/common/Table/TablePagination";
import {
  deletescope3Category5Data,
  fetchCurrencyData,
  fetchScope3Category5Data,
  fetchscope3Category5InputData,
  fetchSiteData,
  fetchUnitData,
  fetchWasteMaterial,
  fetchWasteTreatment,
  fetchWasteTreatmentMapping,
} from "../../features/scope3/category5/Scope3Category5Thunk";
import useApi from "../../customHooks/useApi";
import Category5FormPopUp from "../../components/scope3/category5/Category5FormPopUp";
import TableComponent from "./TableComponent";

const Scope3Category5 = () => {
  const [apiIntegration, setApiIntegration] = useState(false);
  const [trendLine, setTrendLine] = useState(false);

  const api = useApi();
  const dispatch = useDispatch();
  const scope3Category5InputRecords = useSelector(
    (state) => state?.scope3Category5?.scope3Category5InputRecords || []
  );
  const scope3Category5TreatedInputRecords = useSelector(
    (state) => state?.scope3Category5?.scope3Category5InputRecordsTreated || []
  );
  const itemsPerPage = useSelector(
    (state) => state?.scope3Category5?.itemsPerPage
  );
  const totalPages = useSelector((state) => state?.scope3Category5?.totalPages);
  const totalCount = useSelector((state) => state?.scope3Category5?.totalCount);
  const currentPage = useSelector(
    (state) => state?.scope3Category5?.currentPage
  );

  const [files, setFiles] = useState(null);
  const [manualEntry, setManualEntry] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedWasteType, setSelectedWasteType] = useState(1);
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isCategory5FormPopupOpen, setIsCategory5FormPopupOpen] =
    useState(false);

  const headingsToDataKeyMap = {
    " ": "",
    "PERIOD START": "period_start",
    "PERIOD END": "period_end",
    SITE: "site",
    "WASTE MATERIAL": "waste_material",
    "WASTE IMPACT": "waste_impact",
    " QUANTITY": "quantity",
    UNIT: "unit",
    "AMOUNT SPENT": "amount_spent",
    CURRENCY: "currency",
    EDIT: "edit",
    DELETE: "",
  };

  const headingsToDataKeyMapforTreated = {
    " ": "",
    "PERIOD START": "period_start",
    "PERIOD END": "period_end",
    SITE: "site",
    "WASTE MATERIAL": "waste_material",
    "TREATMENT MECHANISM": "treatment_mechanism",
    "PROPORTION OF WASTE TREATED": "proportion_of_waste_treated",
    "TREATED QUANTITY": "treated_quantity",
    UNIT: "unit",
    "AMOUNT SPENT": "amount_spent",
    CURRENCY: "currency",
    EDIT: "edit",
    DELETE: "",
  };

  const handleDelete = (row) => {
    if (row.id) {
      dispatch(deletescope3Category5Data({ api, id: row.id }));
    } else {
      toast.error("Failed please try again");
    }
  };

  const handleEdit = (row) => {
    dispatch(resetscope3Category5Form());
    setActionType("edit");
    setSelectedRowData(row);
    setIsCategory5FormPopupOpen(true);
  };

  const handleCopy = (row) => {
    dispatch(resetscope3Category5Form());
    setActionType("copy");
    setSelectedRowData(row);
    setIsCategory5FormPopupOpen(true);
  };

  const openPopupForm = () => {
    dispatch(resetscope3Category5Form());
    setActionType("newEntry");
    setIsCategory5FormPopupOpen(true);
  };

  const closePurchasePopupForm = () => {
    setIsCategory5FormPopupOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };
  const switchTab = (tabId) => {
    switch (tabId) {
      case 1:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
      case 2:
        setManualEntry(false);
        setApiIntegration(true);
        setTrendLine(false);
        break;
      case 3:
        setManualEntry(false);
        setApiIntegration(false);
        setTrendLine(true);
        break;
      default:
        setManualEntry(true);
        setApiIntegration(false);
        setTrendLine(false);
        break;
    }
    setSelectedTab(tabId);
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };

  const handleFormChange = async (e) => {
    // const { name, type, value } = e.target;
    // if (type === "file") {
    //   const files = e.target.files[0];
    //   setFiles(files);
    //   dispatch(setscope3Category5Form({ name, value: files }));
    // } else {
    //   dispatch(setscope3Category5Form({ name, value }));
    // }
  };

  const handleFormSubmit = async () => {
    setManualEntry(true);
    setIsCategory5FormPopupOpen(false);
  };

  const handleChangeTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleWasteTypeChange = (type) => {
    setSelectedWasteType(type);
  };

  useEffect(() => {
    const initializeData = async () => {
    
      
      
      await dispatch(fetchUnitData({ api }));
      await dispatch(fetchWasteMaterial({ api }));
      await dispatch(fetchSiteData({ api }));
      await dispatch(fetchCurrencyData({ api }));
      await dispatch(fetchWasteTreatment({api}))
      await dispatch(fetchWasteTreatmentMapping({api}))
    };

    initializeData();
  }, [dispatch, api]);

  return (
    <>
      <Header
        pageTitle="Category 5"
        PageIcon={Scope3Icon}
        onClick={openPopupForm}
      />
      <div className="flex flex-col main-container w-full px-10 py-6">
        <div className="flex justify-between items-center text-xs">
          <div className="flex">
            <button
              className={`flex  items-center border border-green-600 rounded-md mr-4 cursor-pointer px-2 w-48 h-8 ${
                selectedWasteType === 1
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleWasteTypeChange(1)}
            >
              <WasteTreatedIcon className="fill-current w-4 h-4" />
              <span className="px-2">Waste Produced</span>
            </button>
            <button
              className={`flex border border-green-600 items-center rounded-md mr-4 cursor-pointer px-2  w-48 h-8 ${
                selectedWasteType === 2
                  ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                  : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
              }`}
              onClick={() => handleWasteTypeChange(2)}
            >
              <WasteTreatedIcon className="fill-current w-4 h-4" />
              <span className="px-2">Waste Treated</span>
            </button>
          </div>
          <div className="flex"></div>
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          <TableMeta
            recordType={1}
            totalCount={totalCount}
            openPopUpForm={openPopupForm}
            switchTab={switchTab}
            selectedTab={selectedTab}
          />

          {manualEntry && (
            <>
              {" "}
              {selectedWasteType === 1 && (
                <TableComponent
                  headings={headingsToDataKeyMap}
                  dataRows={scope3Category5InputRecords}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  handleFileDownload={handleFileDownload}
                />
              )}
              {selectedWasteType === 2 && (
                <TableComponent
                  headings={headingsToDataKeyMapforTreated}
                  dataRows={scope3Category5TreatedInputRecords}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCopy={handleCopy}
                  handleFileDownload={handleFileDownload}
                />
              )}
          <div className="flex w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
            <AddIconBig onClick={() => openPopupForm(1)} />
          </div>
            </>
          )}

          {apiIntegration && (
            <>
              <h1>API Integration tab</h1>
            </>
          )}
          {trendLine && (
            <>
              <h1>TrendLine tab</h1>
            </>
          )}
          <TablePagination
            paginationObject={{
              paginationEnabled: true,
              currentPage: currentPage,
              totalPages: totalPages,
              itemsPerPage: itemsPerPage,
              handleItemsPerPage: handleItemsPerPage,
              handlePageChange: handlePageChange,
            }}
          />
        </div>
      </div>
      {isCategory5FormPopupOpen && (
        <Category5FormPopUp
        selectedWasteType={selectedWasteType}
          handleFormChange={handleFormChange}
          file={files}
          type={actionType}
          data={selectedRowData}
          closePopup={closePurchasePopupForm}
        />
      )}
    </>
  );
};

export default Scope3Category5;
