import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsLoading } from "../../common/commonSlice.js";
import { toast } from "react-toastify";
import {
  setCurrencyData,
  setSiteData,
  setTreatmentMechanism,
  setUnitData,
  setdeletes3c9Data,
  setscope3Category5InputRecords,
  setscope3Category5WasteProduceForm,
  setscope3Category5WasteTreatedForm,
  setwasteMaterial,
  setwasteTreatmentMapping,
} from "./Scope3Category5Slice.js";
import { getOrganizationId } from "../../../components/common/parseJwt.js";
export const fetchscope3Category5InputData = createAsyncThunk(
  "scope3Category5/fetchscope3Category5InputData",
  async ({ api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();

    let apiUrl = "/scope3/category5/inputdata/" + organization_id;

    try {
      const response = await api.get(`${apiUrl}`);

      // console.log(response)
      if (response.status === 200 && response.data.success) {
        dispatch(
          setscope3Category5InputRecords(
            response?.data?.data.map((item) => {
              return {
                ...item,
              };
            })
          )
        );
        // dispatch(setCurrentPage(response.data.currentPage));
        // dispatch(setItemsPerPage(response.data.itemsPerPage));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setTotalCount(response.data.totalCount));
        // return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchSiteData = createAsyncThunk(
  "scope3Category5/fetchSiteData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();
    try {
      const response = await api.get(
        `/scope3/category5/${organization_id}/sites`
      );

      if (response.status === 200 && response.data.success) {
        dispatch(setSiteData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchUnitData = createAsyncThunk(
  "scope3Category5/fetchUnitData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category5/units`);

      if (response.status === 200 && response.data.success) {
        dispatch(setUnitData(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchWasteMaterial = createAsyncThunk(
  "scope3Category5/fetchWasteMaterial",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category5/waste-material`);

      if (response.status === 200 && response.data.success) {
        dispatch(setwasteMaterial(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchWasteTreatment= createAsyncThunk(
  "scope3Category5/fetchWasteTreatment",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category5/waste-treatment`);

      if (response.status === 200 && response.data.success) {
        dispatch(setTreatmentMechanism(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const fetchWasteTreatmentMapping= createAsyncThunk(
  "scope3Category5/fetchWasteTreatmentMapping",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    try {
      const response = await api.get(`/scope3/category5/waste-treatment-mapping`);

      if (response.status === 200 && response.data.success) {
        dispatch(setwasteTreatmentMapping(response.data.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const fetchCurrencyData = createAsyncThunk(
  "scope3Category5/fetchCurrencyData",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    try {
      const response = await api.get(`/scope3/category5/currencies`);

      // console.log(response.data.data);
      if (response.status === 200 && response.data.success) {
        dispatch(setCurrencyData(response?.data?.data));
      }

      //   return response.data;
    } catch (error) {
      // Handle other API call errors
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category5WasteProduceData = createAsyncThunk(
  "scope3Category5/postscope3Category5Data",
  async ({ api }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    const accessToken = getState().auth.accessToken;
    const { id, ...scope3Category5Form } =
      getState().scope3Category5.scope3Category5WasteProduceForm;
    const organization_id = getOrganizationId();
    try {
      const response = await api.post(
        `/scope3/category5/${organization_id}/data`,
        {
          ...scope3Category5Form,
      
        }
       
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        // dispatch(resetscope3Category5Form());
        dispatch(fetchscope3Category5InputData({ api }));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const postscope3Category5WasteTreatedData = createAsyncThunk(
  "scope3Category5/postscope3Category5WasteTreatedData",
  async ({ api,i,formValues }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const { scope3Category5WasteProduceForm,scope3CategoryWasteTreatedForm} =
      getState().scope3Category5;
     
    const organization_id = getOrganizationId();
    try {
      const response = await api.post(
        `/scope3/category5/${organization_id}/data`,
        {
         
          waste_produced:scope3Category5WasteProduceForm,waste_treated:formValues
        
        },
        {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`,
          // },
        }
      );
      // console.log(response);
      if (response.status === 200 || response.status === 201) {
        // dispatch(resetscope3Category5Form());
        dispatch(fetchscope3Category5InputData({ api }));
        toast.success("Record created successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error?.response?.data?.error || "Record creation failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const scope3Category5UploadEvidence = createAsyncThunk(
  "scope3Category5/scope3Category5UploadEvidence",
  async ({ files, api, type }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    // const accessToken = getState().auth.accessToken;
    const accessToken = localStorage.getItem("accessToken");
    const organization_id = getOrganizationId();
    console.log(formData)
    try {
      const response = await api.post(
        `/scope3/category5/${organization_id}/evidence`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setIsLoading(false)); 
        toast.success("Evidence uploded successfully", {
          theme: "dark",
        });
        // return response?.data?.path
      }
      return response.data;
    } catch (error) {
      console.error("Error uploading evidence", error);
      toast.error("uploading evidence failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updatescope3Category5Data = createAsyncThunk(
  "scope3Category5/updatescope3Category5Data",
  async ({ api, id }, { getState, dispatch }) => {
    dispatch(setIsLoading(true));
    // const accessToken = getState().auth.accessToken;
    const scope3Category5Form = getState().scope3Category5.scope3Category5Form;
    // console.log(scope3Category5Form, id);
    const organization_id = getOrganizationId();
    try {
      const response = await api.put(
        `/scope3/category5/${organization_id}/data/${id}`,
        scope3Category5Form
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record updated successfully", {
          theme: "dark",
        });
        dispatch(fetchscope3Category5InputData({ api }));
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Record update failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const deletescope3Category5Data = createAsyncThunk(
  "scope3Category5/deletescope3Category5Data",
  async ({ api, id }, { _, dispatch }) => {
    dispatch(setIsLoading(true));

    const organization_id = getOrganizationId();
    try {
      const response = await api.delete(
        `/scope3/category5/${organization_id}/data/${id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        dispatch(setdeletes3c9Data(id));
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Record delete failed", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
