import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationId } from "../../../components/common/parseJwt";
import {
  setAllData,
  setnonrenewableData,
  setrenewableData,
} from "./BRSRSlices";
import { setIsLoading } from "../../../features/common/commonSlice";
import { toast } from "react-toastify";
import {
  calculateTotalEmissions,
  processData,
  processFinancialYearData,
} from "./ProcessData";

export const getPdfAllData = createAsyncThunk(
  "BESR/getPdfAllData",
  async ({ api }, { _, dispatch }) => {
    dispatch(setIsLoading(true));
    const organization_id = getOrganizationId();

    try {
      const response = await api.get(
        `/energy/${organization_id}/summary-data`
        // `/all-data/pdf-download/${organization_id}`
      );
      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        const Alldata  = response?.data?.data;
        const updatedData = Alldata.map((item) => {
          return {
            ...item,
            module: item?.ModuleMaster?.module,
            sub_module: item?.SubModuleMaster?.sub_module,
          };
        });
        const processAllData = processFinancialYearData(updatedData);
        // console.log(calculateTotalEmissions(processAllData["Scope 3"]),updatedData,processAllData);
        dispatch(
          setAllData(
            processData(
              { ...processAllData["Scope 1"], ...processAllData["Scope 2"] },
              [2023, 2024]
            )
          )
        );
        dispatch(
          setrenewableData(
            processData(
              { ...processAllData["Scope 1"], ...processAllData["Scope 2"] },
              [2023, 2024],
              "renewable"
            )
          )
        );
        dispatch(
          setnonrenewableData(
            processData(
              { ...processAllData["Scope 1"], ...processAllData["Scope 2"] },
              [2023, 2024],
              "non-renewable"
            )
          )
        );
      }
    } catch (error) {
      console.error("Error geting data:", error);
      toast.error(error?.response?.data?.error || "Error fetching data", {
        theme: "dark",
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
