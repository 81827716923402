import React, { useEffect } from "react";
import Header from "../../components/common/Header";
import { ReactComponent as DownloadIcon } from "../../app/assets/DownloadIcon.svg";
import { ReactComponent as BRSR } from "../../app/assets/BRSRIcon.svg";
import { PDFDownloadLink, PDFViewer, Font } from "@react-pdf/renderer";
import { TempleteDocument } from "./Document";
import useApi from "../../customHooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { getPdfAllData } from "./Redux/BRSRThunk";

// Register the font


const Index = () => {
  const {AllData,renewableData,nonrenewableData}=useSelector(state=>state.BRSR)
  const dispatch=useDispatch()
  const api=useApi()
  
  let data={...AllData,...renewableData,...nonrenewableData}
  // console.log(data)
useEffect(()=>{
dispatch(getPdfAllData({api}))
},[])
  return (
    <div className="h-screen">
      <Header PageIcon={BRSR} pageTitle={"BRSR"} />
      <div className="mt-4 flex justify-end px-5" id="BRSR">
        <div
          className="flex flex-row justify-center items-center w-28 h-8 mb-5 cursor-pointer border border-green-600 rounded-md text-green-600 hover:bg-green-600 hover:text-white mr-2"
        >
          <span className="mr-2 ">
            <DownloadIcon className="colored-svg w-6 h-6" />
          </span>
          <PDFDownloadLink document={<TempleteDocument data={data} />} fileName="report.pdf">
            {({ loading }) => (
              <span className="text-xs">{loading ? 'Loading...' : 'Download'}</span>
            )}
          </PDFDownloadLink>
        </div>
      </div>

      {data?<div className="pdf-container flex justify-center p-5">
        <PDFViewer style={{ width: '100%', height: '600px' }}>
          <TempleteDocument data={data} />
        </PDFViewer>
      </div>:"Loading..."}

      <style>{`
        .pdf-container {
          margin: 0 auto;
          max-width: 1200px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
        .page-number {
          text-align: center;
          {/* margin-top: 10px; */}
          font-size: 14px;
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default Index;
