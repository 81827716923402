import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  AllData: {},
  renewableData: {},
  nonrenewableData: {},

};

const BESRSlice = createSlice({
  name: "BESR",
  initialState,
  reducers: {
    setAllData(state, action) {
      state.AllData = action.payload;
    },
    setrenewableData(state, action) {
      state.renewableData = action.payload;
    },
    setnonrenewableData(state, action) {
      state.nonrenewableData = action.payload;
    },
    
  },
});

export const {
  setAllData,setnonrenewableData,setrenewableData
  
} = BESRSlice.actions;
export default BESRSlice.reducer;
